import React, {useState, useEffect} from "react";
import {Modal, Button} from "react-bootstrap";
import {makeStyles} from "@material-ui/core/styles";
import {useDeleteStudentMutation} from "../../../../redux/api/Students/studentsApi";

const useStyles = makeStyles((theme) => ({
    textarea: {
        width: "100% !important",
        height: "100px !important",
        border: "1px solid rgba(0, 0, 0, 0.12)",
    },
}));

export default function Remove({onHide, show, studentId}) {
    const classes = useStyles();

    const [deleteStudent, {isLoading, error, isSuccess}] = useDeleteStudentMutation();

    const onDeleteUser = (e) => {
        e.preventDefault();
        deleteStudent(studentId)
    };

    if (isSuccess) {
        onHide(false)
    }


    return (
        <Modal show={show} onHide={onHide} aria-labelledby="delete" centered>
            <form onSubmit={onDeleteUser}>
                <Modal.Header closeButton>
                    <Modal.Title id="delete">Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-12 pl-4 pr-4">
                        <div className="row">Are you sure to delete ?</div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button disabled={isLoading} variant="light" onClick={() => onHide(false)}>
                        Cancel
                    </Button>
                    <Button disabled={isLoading} variant="danger" type="submit">Delete</Button>
                </Modal.Footer>
            </form>
        </Modal>
    );

}
