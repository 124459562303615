import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {CircularProgress, Divider} from "@material-ui/core";
import {useGetTrialExamsQuery} from "redux/api/TrialExams/trialExamsApi";
import {format} from "date-fns"
import EditIcon from "app/components/icons/EditIcon";
import DeleteIcon from "app/components/icons/DeleteIcon";
import RenderIf from "app/components/RenderIf";
import Create from "./modals/Create";
import Edit from "./modals/Edit";
import Remove from "./modals/Remove";
import Pagination from "@material-ui/lab/Pagination";

const useStyles = makeStyles({
    wrapper: {
        height: " calc(100% - 65px)",
        paddingTop: "12px",
        display: "flex",
        flexDirection: "column",
        gap: "8px"
    },
    divider: {
        height: "1px",
        width: "100%"
    },
    label: {
        width: "100px"
    },
    card: {
        padding: "24px",
        background: "#FFFFFF",
        boxShadow: "0px 2px 18px rgba(62, 74, 97, 0.12)",
        borderRadius: "12px"
    },
    cardTitle: {
        fontWeight: "500",
        fontSize: "22px",
        lineHeight: "33px",
        color: "#050A46",
        marginBottom: "12px"
    },
    cardDescription: {
        display: "flex",
        gap: "16px",
        marginBottom: "24px"
    },
    cardTextContainer: {
        display: "flex",
        gap: "8px",
    },
    cardText: {
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "21px",
        color: "#424460",
    },
    cardButtonsContainer: {
        display: "flex",
        gap: "32px"
    },
    cardButton: {
        background: "unset",
        border: "unset",
        fontWeight: "500",
        fontSize: "16px",
        lineHeight: "24px",
        color: "#424460",
        "& svg": {
            marginRight: "8px"
        }
    },
    progress: {
        color: "#34a9fc"
    }
});

export default function TrialExams() {
    const [showCreate, setShowCreate] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [showRemove, setShowRemove] = useState(false);
    const [examId, setExamId] = useState("");
    const [page, setPage] = useState(1);
    const [skip, setSkip] = useState(0);
    const take = 10;

    const {data, isLoading} = useGetTrialExamsQuery({
        skip,
        take,
        orderBy: true,
        sortField: "examDate"
    });

    const classes = useStyles();

    const count = Math.ceil(data?.count / take);

    const handleChangePage = (e, page) => {
        setPage(page)
        const tempSkip = (page - 1) * take
        setSkip(tempSkip);
        window.scrollTo({top: 0, behavior: "smooth"})
    };

    return (
        <div className="row bg-white rounded h-100">
            <div className="col-12 d-flex align-items-center py-4 height-65">
                <h1 className="display-4 mb-0">Trial exams</h1>
                <div className="ml-auto">
                    <button onClick={() => setShowCreate(true)} className="button-primary">Add a new exam
                    </button>
                </div>
            </div>
            <Divider className={classes.divider}/>
            <div className={`col-12 ${classes.wrapper}`}>
                {
                    isLoading
                        ? <div className="w-100 d-flex justify-content-center"><CircularProgress
                            className={classes.progress}/></div>
                        : data?.data?.map(exam => (
                            <div key={exam.id} className={classes.card}>
                                <h2 className={classes.cardTitle}>{exam.name}</h2>
                                <div className={classes.cardDescription}>
                                    <div className={classes.cardTextContainer}>
                                        <span className={classes.cardText}>
                                            Participation count:
                                        </span>
                                        <span className={classes.cardText}>
                                            {exam.participationCount}
                                        </span>
                                    </div>
                                    <div className={classes.cardTextContainer}>
                                        <span className={classes.cardText}>
                                            Price:
                                        </span>
                                        <span className={classes.cardText}>
                                            {`${exam.price} $`}
                                        </span>
                                    </div>
                                    <div className={classes.cardTextContainer}>
                                        <span className={classes.cardText}>
                                            Exam date:
                                        </span>
                                        <span className={classes.cardText}>
                                            {exam.examDate && format(new Date(exam.examDate), "dd MMMM yyyy HH:mm")}
                                        </span>
                                    </div>
                                    <div className={classes.cardTextContainer}>
                                        <span className={classes.cardText}>
                                            Duration:
                                        </span>
                                        <span className={classes.cardText}>
                                            {exam.duration}
                                        </span>
                                    </div>
                                </div>
                                <div className={classes.cardButtonsContainer}>
                                    <button onClick={() => {
                                        setShowEdit(true);
                                        setExamId(exam.id)
                                    }
                                    } className={classes.cardButton}>
                                        <EditIcon/>
                                        Edit
                                    </button>
                                    <button onClick={() => {
                                        setShowRemove(true);
                                        setExamId(exam.id)
                                    }
                                    } className={classes.cardButton}>
                                        <DeleteIcon/>
                                        Delete
                                    </button>
                                </div>
                            </div>
                        ))
                }
                {data?.count > take && (
                    <div className="py-2">
                        <Pagination onChange={(e, page) => handleChangePage(e, page)}
                                    count={count} page={page}/>
                    </div>
                )}
            </div>
            <RenderIf condition={showCreate}>
                <Create show={showCreate} onHide={setShowCreate}/>
            </RenderIf>
            <RenderIf condition={showEdit}>
                <Edit show={showEdit} onHide={setShowEdit} id={examId}/>
            </RenderIf>
            <RenderIf condition={showRemove}>
                <Remove show={showRemove} onHide={setShowRemove} id={examId}/>
            </RenderIf>
        </div>
    );
}