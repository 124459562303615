import {createSlice} from "@reduxjs/toolkit";
import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";

const initialState = {
    booksState: [],
    step: "books",
    hasUndeliveredBook: false
}

export const booksSlice = createSlice({
    initialState,
    name: "books",
    reducers: {
        setBooks: (state, action) => {
            state.booksState = action.payload
        },
        setStep: (state, action) => {
            state.step = action.payload
        },
        setUndeliveredBook: (state, action) => {
            state.hasUndeliveredBook = action.payload
        }
    }
})

export const reducer = persistReducer({
    key: 'Math:book',
    storage,
    whitelist: ['hasUndeliveredBook']
}, booksSlice.reducer);

export default reducer;
export const {setBooks, setStep, setUndeliveredBook} = booksSlice.actions