import React, {useEffect} from "react";
import {Modal, Button} from "react-bootstrap";
import {CircularProgress, TextField} from "@material-ui/core";
import {useEditStudentCoinMutation, useGetStudentByIdQuery} from "../../../../redux/api/Students/studentsApi";
import {useFormik} from "formik";
import * as Yup from "yup";

export default function EditCoin({show, onHide, studentId}) {
    const editData = useGetStudentByIdQuery(studentId);
    const [updateCoins, {isLoading, isSuccess}] = useEditStudentCoinMutation();


    const initialValues = {
        mycCoins: "",
    };

    const validationSchema = Yup.object({
        mycCoins: Yup.number("Only number").required("Please enter a value.")
    })

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            const {mycCoins} = values
            const body = {id: studentId, mycCoins}
            updateCoins(body)
        }
    });

    const getInputClasses = (fieldName) => {
        if (formik.touched[fieldName] && formik.errors[fieldName]) {
            return true;
        }
        if (formik.touched[fieldName] && !formik.errors[fieldName]) {
            return false;
        }

        return false;
    };

    useEffect(() => {
        if (isSuccess) {
            onHide(false)
        }
    });

    useEffect(() => {
        if (editData.isSuccess && !editData.isLoading) {
            const {mycCoins} = editData.data
            formik.setFieldValue("mycCoins", mycCoins);
        }
    }, [editData.isLoading, editData.isSuccess])


    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Edit MYC Coins</Modal.Title>
            </Modal.Header>
            <form onSubmit={formik.handleSubmit}>
                <Modal.Body>
                    {editData.isLoading
                        ? <div className="d-flex justify-content-center"><CircularProgress style={{color: "#34A9FC"}}/>
                        </div>
                        : <div className="col-12 pl-4 pr-4">
                            <div className="row">
                                <div className="col-12 col-md-12 col-lg-12">
                                    <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                        <TextField
                                            name="mycCoins"
                                            id="mycCoins"
                                            label="MYC Coins"
                                            placeholder="MYC Coins"
                                            className="w-100"
                                            error={getInputClasses("mycCoins")}
                                            {...formik.getFieldProps("mycCoins")}
                                        />
                                        {formik.touched.mycCoins && formik.errors.mycCoins ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">
                                                    {formik.errors.mycCoins}
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>

                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => {
                        onHide(false);
                    }}  variant="light">Close</Button>
                    <Button disabled={isLoading} type="submit" variant="info">Update</Button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}