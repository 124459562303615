import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "react-bootstrap/Alert";
import InfoIcon from "@material-ui/icons/Info";
import ErrorIcon from "@material-ui/icons/Error";

const useStyles = makeStyles((theme) => ({
  alertRoot: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(2),
  },
  alert: {
    width: "100%",
    maxWidth: "360px",
    display: "flex",
    padding: theme.spacing(3),
  },
  icon: {
    fontSize: "25px",
    flexShrink: 0,
  },
  message: {
    paddingLeft: theme.spacing(1),
    display: "flex",
    alignItems: "center",
  },
}));

export default function AlertComponent({ variant, message }) {
  const classes = useStyles();
  return (
    <div className={classes.alertRoot}>
      <Alert variant={variant} className={classes.alert}>
        {variant === "info" ? (
          <InfoIcon className={classes.icon} />
        ) : (
          <ErrorIcon />
        )}
        <span className={classes.message}>{message}</span>
      </Alert>
    </div>
  );
}
