import React, {useEffect} from "react";
import {Modal, Button} from "react-bootstrap";
import {makeStyles} from "@material-ui/core/styles";
import {useRemoveStudentMutation} from "redux/api/Groups/groupsApi";

const useStyles = makeStyles((theme) => ({
    textarea: {
        width: "100% !important",
        height: "100px !important",
        border: "1px solid rgba(0, 0, 0, 0.12)",
    },
    warning: {
        color: "red",
        fontSize: "16px",
        marginTop: "10px"
    },
    title: {
        fontSize: "17px",
    }
}));

export default function RemoveStudent({onHide, show, studentId, groupId}) {
    const classes = useStyles();

    const [removeStudent, {isSuccess}] = useRemoveStudentMutation();

    const onDeleteUser = (e) => {
        e.preventDefault();
        removeStudent({
            groupId,
            studentId
        })
    };

    useEffect(() => {
        if(isSuccess) {
            onHide(false);
        }
    }, [isSuccess])

    return (
        <Modal show={show} onHide={onHide} aria-labelledby="delete" centered>
            <form onSubmit={onDeleteUser}>
                <Modal.Header closeButton>
                    <Modal.Title id="delete">Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-12 pl-4 pr-4">
                        <div className={`row ${classes.title}`}>Are you sure to remove this student ?
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={() => onHide(false)}>
                        Cancel
                    </Button>
                    <Button variant="danger" type="submit">Delete</Button>
                </Modal.Footer>
            </form>
        </Modal>
    );

}
