import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";
import {lighten, makeStyles} from "@material-ui/core/styles";
import PlusIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import CircularProgress from "@material-ui/core/CircularProgress";


const headRows = [
    {
        id: "firstName",
        label: "First Name",
    },
    {
        id: "lastName",
        label: "Last Name",
    },
    {
        id: "roles",
        label: "Role",
    },
    {
        id: "email",
        label: "Email",
    },
    {
        id: "phone",
        label: "Phone",
    },
    {
        id: "tools",
        label: "Operations",
    },
];

const useStyles = makeStyles((theme) => ({
    height: {
        height: "calc(100% - 65px)",
    },
    root: {
        width: "100%",
        height: "100%",
        padding: "24px 0",
    },
    paper: {
        width: "100%",
        height: "100%",
    },
    table: {
        minWidth: 750,
    },
    tableWrapper: {
        overflowX: "auto",
    },
    toolbar: {
        minHeight: theme.spacing(2),
    },
    progresRoot: {
        width: "100%",
        padding: "32px 16px",
    },
    progress: {
        margin: "auto",
        display: "block",
        color: "#34a9fc",
    },
}));

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
        paddingTop: theme.spacing(1),
        flexWrap: "wrap",
    },
    highlight:
        theme.palette.type === "light"
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    spacer: {
        flex: "1 1 100%",
    },
    actions: {
        color: theme.palette.text.secondary,
    },
    title: {
        flex: "0 0 auto",
    },
    label: {
        fontSize: ".875rem",
        width: "100%",
    },
    toolbar: {
        zIndex: "10",
    },
}));

const EnhancedTableToolbar = ({setShowCreate}) => {
    const classes = useToolbarStyles();

    return (
        <Toolbar className={classes.root}>
            <div className={classes.actions}>
                <Tooltip title="Create" placement="top">
                    <button
                        className="flex-shrink-0 btn btn-sm btn-icon btn-bg-light btn-text-primary btn-hover-primary mr-2 h5 rounded-circle flex-shrink-0"
                        onClick={() => setShowCreate(true)}
                    >
                        <PlusIcon/>
                    </button>
                </Tooltip>
            </div>
        </Toolbar>
    )
}

function EnhancedTableHead({order, orderBy, onRequestSort}) {

    return (
        <TableHead>
            <TableRow>
                {headRows.map(row => (
                    <TableCell key={row.id}>
                        {row.id === "tools" ? (
                            row.label
                        ) : (
                            <TableSortLabel
                                active={orderBy === row.id}
                                direction={order ? "asc" : "desc"}
                                onClick={() => {
                                    return row.id !== "tools" ? onRequestSort(row.id) : null;
                                }}
                            >
                                {row.label}
                            </TableSortLabel>
                        )}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}

export default function EnhancedTable({setShowCreate, data, isLoading, setUserId, setShowEdit, setShowChangePassword, setShowRemove, field, order, setField, setOrder}) {
    const classes = useStyles();

    const onRequestSort = (fieldName) => {
        let tempOrder = fieldName === field && !order
        setField(fieldName);
        setOrder(tempOrder)
    }

    return (
        <div className={`col-12 ${classes.height}`}>
            <div className={classes.root}>
                <Paper className={classes.paper}>
                    <EnhancedTableToolbar setShowCreate={setShowCreate}  onRequestSort={onRequestSort}/>
                    <div
                        className={classes.tableWrapper}
                    >
                        {isLoading ?
                            <div className={classes.progresRoot}>
                                <CircularProgress className={classes.progress}/>
                            </div>
                            : <Table
                                className={classes.table}
                                aria-labelledby="tableTitle"
                                stickyHeader
                            >
                                <EnhancedTableHead onRequestSort={onRequestSort} order={order}/>
                                <TableBody>
                                    {data && data?.data.map((row, index) => (
                                        <TableRow key={index}>
                                            <TableCell component="th" scope="row">
                                                {row.firstName}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row.lastName}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row.roles}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row.email}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row.phone}
                                            </TableCell>
                                            <TableCell>
                                                <Tooltip title="Change password">
                                                    <button
                                                        aria-label="changePassword"
                                                        className="btn btn-sm btn-icon btn-bg-light btn-text-warning btn-hover-dark mr-2"
                                                        onClick={() => {
                                                            setUserId(row.id);
                                                            setShowChangePassword(true);
                                                        }}
                                                    >
                                                        <VpnKeyIcon/>
                                                    </button>
                                                </Tooltip>
                                                <Tooltip title="Edit">
                                                    <button
                                                        aria-label="edit"
                                                        className="btn btn-sm btn-icon btn-bg-light btn-text-dark btn-hover-dark mr-2"
                                                        onClick={() => {
                                                            setUserId(row.id);
                                                            setShowEdit(true);
                                                        }}
                                                    >
                                                        <EditIcon/>
                                                    </button>
                                                </Tooltip>
                                                <Tooltip title="Delete" placement="top">
                                                    <button
                                                        aria-label="Delete"
                                                        className="btn btn-sm btn-icon btn-bg-light btn-text-danger btn-hover-danger"
                                                        onClick={() => {
                                                            setUserId(row.id);
                                                            setShowRemove(true)
                                                        }}
                                                    >
                                                        <DeleteIcon/>
                                                    </button>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        }

                    </div>
                </Paper>
            </div>
        </div>
    )
}