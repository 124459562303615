import {createApi} from "@reduxjs/toolkit/query/react";
import {APIBaseQuery} from "../AxiosBase";
import {v4 as uuid} from "uuid";

export const ourStudentsApi = createApi({
    reducerPath: "ourStudentsApi",
    baseQuery: APIBaseQuery,
    keepUnusedDataFor: 1,
    endpoints: (builder) => ({
        getOurStudents: builder.query({
            query(filter) {
                const {skip, take} = filter
                return {
                    url: `our-students`,
                    params: {
                        skip,
                        take,
                        sortField: "updated",
                        orderBy: false
                    }
                }
            },
            keepUnusedDataFor: 1
        }),
        createShowCase: builder.mutation({
            query(form){
                return {
                    url: "our-students",
                    method: "POST",
                    headers: {
                        "x-requestid": uuid()
                    },
                    data: form
                }
            },
            keepUnusedDataFor: 1
        }),
        getShowCaseById: builder.query({
            query(id){
                return {
                    url: `our-students/${id}`,
                }
            },
            keepUnusedDataFor: 1
        }),
        updateShowCase: builder.mutation({
            query(body){
                return {
                    url: "our-students",
                    method: "PUT",
                    headers: {
                        "x-requestid": uuid()
                    },
                    data: body
                }
            },
            keepUnusedDataFor: 1
        }),
        deleteShowCase: builder.mutation({
            query(id){
                return {
                    url: `our-students/${id}`,
                    method: "DELETE",
                    headers: {
                        "x-requestid": uuid()
                    }
                }
            }
        })

    })
})

export const {useGetOurStudentsQuery, useCreateShowCaseMutation, useGetShowCaseByIdQuery, useUpdateShowCaseMutation, useDeleteShowCaseMutation} = ourStudentsApi;