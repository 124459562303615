import React, {useEffect, useRef, useState} from 'react';
import {Modal, Button} from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import Input from "@material-ui/core/Input";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useCreateContentMutation} from "../../../../redux/api/Courses/coursesApi";

export default function CreateContent({show, onHide, totalCount, itemId, refetch}) {
    const [createContent, {isLoading, isSuccess}] = useCreateContentMutation()

    const initialValues = {
        title: "",
    };
    const validationSchema = Yup.object().shape({
        title: Yup.string().required("Please enter a title."),
    });

    const onSubmitForm = (body) => {
        createContent(body);
    }

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            const body = {
                itemId: itemId,
                title: values.title,
                order: totalCount + 1
            }
            onSubmitForm(body)
        },
    });

    useEffect(() => {
        if(isSuccess){
            refetch();
            onHide(false);
        }
    }, [isLoading])

    const getInputClasses = (fieldName) => {
        if (formik.touched[fieldName] && formik.errors[fieldName]) {
            return true;
        }
        if (formik.touched[fieldName] && !formik.errors[fieldName]) {
            return false;
        }

        return false;
    };

    return (
            <Modal size="lg" show={show} onHide={onHide}>
                <Modal.Header closeButton>
                    <Modal.Title id="create">Create a content</Modal.Title>
                </Modal.Header>
                <form onSubmit={formik.handleSubmit}>
                    <Modal.Body>

                        <div className="col-12 pl-4 pr-4">
                            <div className="row">
                                <div className="col-12 col-md-12 col-lg-12">
                                    <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                        <TextField
                                            id="title"
                                            name="title"
                                            label="Title"
                                            placeholder="Title"
                                            className="w-100"
                                            error={getInputClasses("title")}
                                            {...formik.getFieldProps("title")}
                                        />
                                        {formik.touched.title && formik.errors.title ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">{formik.errors.title}</div>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="light"
                            onClick={() => {
                                onHide(false);
                                formik.resetForm();
                            }}
                        >
                            Close
                        </Button>
                        <Button type="submit" variant="info" className={`px-9`}>
                            Create
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
    )
}