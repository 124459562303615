import React, {useState, useEffect} from "react";
import {Modal, Button} from "react-bootstrap";
import {useGetQuestionByIdQuery} from "../../../../../../redux/api/TestBundles/testBundlesApi";
import {makeStyles} from "@material-ui/core/styles";
import {InputLabel} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

export default function ViewQuestion({show, onHide, questionTitle, questionId}) {

    const {data, isLoading, isSuccess} = useGetQuestionByIdQuery(questionId);

    const useStyles = makeStyles({
        questionTitle: {},
        questionVariant: {},
        correctAnswer: {
            color: "green"
        },
        incorrectAnswer: {
            color: "red"
        },
        progress: {
            margin: "auto",
            display: "block",
            color: "#34a9fc",
        },
    });

    const classes = useStyles();

    useEffect(() => {
        if (isSuccess) {
            window.MathJax.typesetPromise([document.getElementsByClassName("mathjax-container")]);
        }
    }, [isLoading])

    return (
        <Modal show={show} onHide={onHide} aria-labelledby="view" centered size="lg">
            <Modal.Header closeButton>
                <Modal.Title id="view">{questionTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {isLoading
                    ? <CircularProgress className={classes.progress}/>
                    : <>
                        <div className={classes.questionTitle}>
                            <InputLabel id="title">Title</InputLabel>
                            <p className="mathjax-container" dangerouslySetInnerHTML={{__html: data?.title}}></p>
                        </div>
                        <div className={classes.questionVariant}>
                            {data?.testBundleQuestionVariants?.map((variant, index) => (
                                <div key={index}>
                                    <InputLabel
                                        className={variant.isAnswer ? classes.correctAnswer : classes.incorrectAnswer}
                                        id="varinat">{variant.isAnswer ? "Correct variant" : "Incorrect variant"}</InputLabel>
                                    <p className="mathjax-container"
                                       dangerouslySetInnerHTML={{__html: variant.variant}}></p>
                                </div>
                            ))}
                        </div>
                    </>
                }
            </Modal.Body>
        </Modal>
    )
}