import {createApi} from "@reduxjs/toolkit/query/react";
import {APIBaseQuery} from "../AxiosBase";
import {v4 as uuid} from "uuid";

export const trialExamsApi = createApi({
    reducerPath: "trialExamsApi",
    baseQuery: APIBaseQuery,
    keepUnusedDataFor: 1,
    tagTypes: ["TrialExams"],
    endpoints: (build) => ({
        getTrialExams: build.query({
            query(params) {
                return {
                    url: `trial-exams`,
                    params
                }
            },
            providesTags: ["TrialExams"]
        }),
        getTrialExamById: build.query({
            query(id) {
                return {
                    url: `trial-exams/${id}`
                }
            }
        }),
        createTrialExam: build.mutation({
            query(body) {
                return {
                    url: 'trial-exams',
                    method: "POST",
                    data: body
                }
            },
            invalidatesTags: ["TrialExams"]
        }),
        updateTrialExam: build.mutation({
            query(body) {
                return {
                    url: 'trial-exams',
                    method: "PUT",
                    data: body
                }
            },
            invalidatesTags: ["TrialExams"]
        }),
        removeTrialExam: build.mutation({
            query(id) {
                return {
                    url: `trial-exams/${id}`,
                    method: "DELETE"
                }
            },
            invalidatesTags: ["TrialExams"]
        })
    })
})

export const {
    useGetTrialExamsQuery,
    useGetTrialExamByIdQuery,
    useCreateTrialExamMutation,
    useUpdateTrialExamMutation,
    useRemoveTrialExamMutation
} = trialExamsApi