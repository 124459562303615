import {createApi} from '@reduxjs/toolkit/query/react';
import FetchBase from "../FetchBase";
import {setCities} from "../../features/Cities/citiesSlice"


export const citiesApi = createApi({
    reducerPath: "citiesApi",
    baseQuery: FetchBase,
    keepUnusedDataFor: 1,
    endpoints: (builder) => ({
        getCities: builder.query({
            query(filter) {
                const {skip,take,field,orderBy,name} = filter
                return {
                    url: `cities/filter?Skip=${skip}&Take=${take}&SortField=${field}&OrderBy=${orderBy}&Name=${name?.name}`
                }
            },
            keepUnusedDataFor: 1,
            transformResponse: (result) => result.data,
            async onQueryStarted(args,{dispatch,queryFulfilled}){
                try {
              
                    const {data} = await queryFulfilled;
                  
                    dispatch(setCities(data));

                     
                } catch (error) {
                 
                }
            }
        }),
        createCities: builder.mutation({
            query(form) {
              return {
                url: "cities",
                method: "POST",
                body: form,
              };
            },
            transformResponse: (result) => result,
            async onQueryStarted(args, { dispatch, queryFulfilled }) {
              try {
                await queryFulfilled;
              } catch (error) {
                console.log("error")
              }
            },
          }),
          updateCities: builder.mutation({
            query(data) {
                return {
                    url: "cities",
                    method: "PUT",
                    body: data
                }
            },
            transformResponse: (result) => result,
            async onQueryStarted(args, {dispatch, queryFulfilled}) {
                try {
                    await queryFulfilled;
                } catch (error) {}
            },
        }),
        deleteCities: builder.mutation({
            query(id) {
                return {
                    url: `cities`,
                    method: "DELETE",
                    body: { id:id}
                }
            },
           
        }),
     
    })
})

export const {useGetCitiesQuery,useUpdateCitiesMutation,useCreateCitiesMutation,useDeleteCitiesMutation} = citiesApi
