import React, {useEffect, useRef, useState} from "react";
import {Modal, Button} from "react-bootstrap";
import {
    useGetBookByIdQuery,
    useUpdateBookMutation,
    useGetCategoriesQuery
} from "../../../../../../redux/api/Books/booksApi";
import {useGetSpecificationsQuery} from "../../../../../../redux/api/Courses/coursesApi";
import {useFormik} from "formik";
import * as Yup from "yup";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ReactSelect from "../../../../UI/ReactMultiSelect";
import Input from "@material-ui/core/Input";
import Preview from "../../../../Courses/components/Preview/Preview";
import {CircularProgress} from "@material-ui/core";
import Button2 from "@material-ui/core/Button";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../../../../_metronic/_helpers";
import ReactQuill from "../../../../../components/ReactQuill"

export default function Edit({show, onHide, bookId}) {
    const [imageSrc, setImageSrc] = useState("");
    const [file, setFile] = useState("");
    const [pdf, setPdf] = useState("");
    const [specificationsData, setSpecificationsData] = useState([]);
    const [multiSelectValue, setMultiSelectValue] = useState([]);

    const book = useGetBookByIdQuery(bookId);
    const categories = useGetCategoriesQuery();
    const specifications = useGetSpecificationsQuery();
    const [updateBook, {isSuccess, error, isLoading}] = useUpdateBookMutation();
    const formRef = useRef(null);

    const initialValues = {
        title: "",
        slug: "",
        shortDescription: "",
        description: "",
        categoryId: "",
        price: "",
        discount: "",
        itemSpecifications: [],
        bookUrl: ""
    };

    const validationSchema = Yup.object({
        title: Yup.string().required("Please enter a title."),
        slug: Yup.string().required("Please enter a slug."),
        shortDescription: Yup.string().required("Please enter a short description."),
        description: Yup.string().required("Please enter a description."),
        categoryId: Yup.string().required("Please enter a category."),
        price: Yup.string().required(),
    });

    const handleSubmit = (values) => {
        const asset = new FormData(formRef.current);
        asset.append("id", bookId);
        asset.append("description", formik.values.description);
        formik.values.itemSpecifications.forEach((spec, index) => asset.append(`itemSpecifications[${index}].specificationId`, spec.specificationId))
        updateBook(asset);
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            handleSubmit(values);
        }
    });

    const getInputClasses = (fieldName) => {
        if (formik.touched[fieldName] && formik.errors[fieldName]) {
            return true;
        }
        if (formik.touched[fieldName] && !formik.errors[fieldName]) {
            return false;
        }

        return false;
    };

    const handleCoverImage = (e) => {
        let reader = new FileReader();
        let file = e.target.files[0];
        setFile(e.target.value);

        reader.onloadend = () => {
            setImageSrc(reader.result);
        };

        reader.readAsDataURL(file);
    };

    const handlePdf = (e) => {
        setPdf(e.target.files[0]);
    }

    const changeSpecifications = (e) => {
        setMultiSelectValue(e)
        let tempSpecifications = e.map(item => ({specificationId: item.value}))
        formik.setFieldValue("itemSpecifications", tempSpecifications);
    }

    useEffect(() => {

        if (specifications.isSuccess) {
            let specificationsOptions = specifications.data.map(item => ({
                value: item.id,
                label: item.name
            }));
            setSpecificationsData(specificationsOptions)
        }
    }, [specifications?.isLoading]);

    useEffect(() => {
        if (book?.isSuccess) {
            const {
                title,
                slug,
                shortDescription,
                description,
                categoryId,
                price,
                discount,
                itemSpecifications,
                coverImage,
                bookUrl
            } = book.data
            formik.setFieldValue("title", title);
            formik.setFieldValue("slug", slug);
            formik.setFieldValue("shortDescription", shortDescription);
            formik.setFieldValue("description", description);
            formik.setFieldValue("categoryId", categoryId);
            formik.setFieldValue("price", price);
            formik.setFieldValue("discount", discount);
            formik.setFieldValue("itemSpecifications", itemSpecifications);
            formik.setFieldValue("bookUrl", bookUrl);
            setImageSrc(coverImage);
        }
    }, [book?.isLoading]);

    useEffect(() => {
        if (specificationsData.length) {
            let indexArr = formik.values.itemSpecifications.map(item => item.specificationId)
            let tempData = specificationsData.filter(specification => indexArr.includes(specification.value))
            setMultiSelectValue(tempData);
        }
    }, [specificationsData?.length]);


    useEffect(() => {
        if (isSuccess) {
            onHide(false)
        }
    }, [isSuccess]);

    return (
        <Modal show={show} onHide={onHide} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Edit the book</Modal.Title>
            </Modal.Header>
            <form onSubmit={formik.handleSubmit} ref={formRef}>
                <Modal.Body>
                    {book.isLoading
                        ? <div className="d-flex justify-content-center"><CircularProgress style={{color: "#34A9FC"}}/>
                        </div>
                        : <div className="col-12 pl-4 pr-4">
                            <div className="row">
                                <div className="col-12 col-md-12 col-lg-6">
                                    <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                        <TextField
                                            id="title"
                                            name="title"
                                            label="Title"
                                            placeholder="Title"
                                            className="w-100"
                                            error={getInputClasses("title")}
                                            {...formik.getFieldProps("title")}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-12 col-lg-6">
                                    <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                        <TextField
                                            id="slug"
                                            name="slug"
                                            label="Slug"
                                            placeholder="Slug"
                                            className="w-100"
                                            error={getInputClasses("slug")}
                                            {...formik.getFieldProps("slug")}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-12 col-lg-12">
                                    <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                        <TextField
                                            id="bookUrl"
                                            name="bookUrl"
                                            label="Book url"
                                            placeholder="Book url"
                                            className="w-100"
                                            error={getInputClasses("bookUrl")}
                                            {...formik.getFieldProps("bookUrl")}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-12 col-lg-12">
                                    <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                        <TextField
                                            id="shortDescription"
                                            name="shortDescription"
                                            label="Short description"
                                            placeholder="Short description"
                                            className="w-100"
                                            error={getInputClasses("shortDescription")}
                                            {...formik.getFieldProps("shortDescription")}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-12 col-lg-12">
                                    <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                        <InputLabel className="w-100" error={getInputClasses("description")}
                                                    title="Description">Description</InputLabel>
                                        <div className="w-100">
                                            <ReactQuill
                                                name="description"
                                                value={formik.values.description}
                                                onChange={(e) => formik.setFieldValue("description", e)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-12 col-lg-12">
                                    <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                        <FormControl className="w-100">
                                            <InputLabel error={getInputClasses("categoryId")}
                                                        htmlFor="categoryId">Category</InputLabel>
                                            <Select
                                                inputProps={{
                                                    name: "categoryId",
                                                    id: "categoryId",
                                                }}
                                                {...formik.getFieldProps("categoryId")}
                                                error={getInputClasses("categoryId")}
                                            >
                                                <MenuItem value="">All</MenuItem>
                                                {
                                                    categories.data?.data?.length && categories.data.data.map((item, index) => (
                                                        <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                                <div className="col-12 col-md-12 col-lg-12">
                                    <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                        <ReactSelect single={multiSelectValue} suggestions={specificationsData}
                                                     label="Course specification"
                                                     handleChangeSingle={changeSpecifications}/>
                                    </div>
                                </div>
                                <div className="col-12 col-md-12 col-lg-6">
                                    <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                        <TextField
                                            id="price"
                                            name="price"
                                            label="Price"
                                            placeholder="Price"
                                            className="w-100"
                                            error={getInputClasses("price")}
                                            {...formik.getFieldProps("price")}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-12 col-lg-6">
                                    <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                        <TextField
                                            id="discount"
                                            name="discount"
                                            label="Discount"
                                            placeholder="Discount"
                                            className="w-100"
                                            error={getInputClasses("discount")}
                                            {...formik.getFieldProps("discount")}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-12 col-lg-6">
                                    <div className="w-100 pt-2 pb-3">
                                        <InputLabel error={getInputClasses("coverFile")} id="coverImage">Cover
                                            image</InputLabel>
                                        <label htmlFor="contained-button-file">
                                            <Input
                                                accept="image/*"
                                                id="contained-button-file"
                                                type="file"
                                                name="coverFile"
                                                label="File {Path}"
                                                placeholder="File Path"
                                                className="w-100"
                                                onChange={handleCoverImage}
                                                value={file}
                                                hidden
                                            />
                                            <Button2 variant="contained" component="span">
                                                <SVG
                                                    src={toAbsoluteUrl(
                                                        "/media/svg/icons/Devices/Camera.svg"
                                                    )}
                                                    className="mr-2"
                                                />
                                                Upload image
                                            </Button2>
                                        </label>
                                        <Preview imageSrc={imageSrc}/>
                                    </div>
                                </div>
                                <div className="col-12 col-md-12 col-lg-6">
                                    <div className="w-100 pt-2 pb-3">
                                        <InputLabel id="answerFile">Answer
                                            PDF</InputLabel>
                                        <label htmlFor="contained-button-file-pdf">
                                            <Input
                                                id="contained-button-file-pdf"
                                                type="file"
                                                name="answerFile"
                                                label="File {Path}"
                                                placeholder="File Path"
                                                className="w-100"
                                                onChange={handlePdf}
                                                hidden
                                            />
                                            <Button2 variant="contained" component="span">
                                                <SVG
                                                    src={toAbsoluteUrl(
                                                        "/media/svg/icons/Devices/Camera.svg"
                                                    )}
                                                    className="mr-2"
                                                />
                                                Upload pdf
                                            </Button2>
                                        </label>
                                    </div>
                                    <div>{pdf?.name}</div>
                                </div>
                            </div>
                        </div>
                    }
                    {error ? error?.data?.errors?.map((error, index) => (
                        <div key={index} className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {error.errorMessage}
                            </div>
                        </div>
                    )) : ""}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={() => onHide(false)}>Close</Button>
                    <Button disabled={isLoading} type="submit" variant="info">Update</Button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}