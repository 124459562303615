import React, {useState, useEffect} from "react";
import {Modal, Button} from "react-bootstrap";
import {useUpdateNoteMutation, useDeleteNoteMutation, useGetStudentNoteQuery} from "redux/api/Groups/groupsApi";
import * as Yup from 'yup';
import {useFormik} from "formik";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

const validationSchema = Yup.object({
    description: Yup.string().required()
})

export default function EditNote({onHide, show, studentId, lessonGroupId, noteId}) {
    const [initialValues] = useState({
        id: noteId,
        lessonGroupId,
        studentId,
        description: ""
    })
    const {data} = useGetStudentNoteQuery(noteId)

    const [updateNote, {isSuccess, isLoading: isMutating}] = useUpdateNoteMutation();

    const [deleteNote, {isSuccess: isDeleted}] = useDeleteNoteMutation();

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            updateNote(values)
        }
    });


    useEffect(() => {
        if (isSuccess || isDeleted) {
            onHide(false);
        }
    }, [isSuccess, isDeleted])

    useEffect(() => {
        if(data) {
            formik.setFieldValue("description", data.description);
        }
    },[data])

    return (
        <Modal show={show} onHide={onHide} aria-labelledby="changeGroup" size="lg" centered>
            <form onSubmit={formik.handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title id="delete">Edit note</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12 col-md-12 col-lg-12">
                            <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                <TextareaAutosize
                                    name="description"
                                    value={formik.values.description}
                                    onChange={formik.handleChange}
                                    className="w-100 h-100"
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => deleteNote(noteId)} disabled={isMutating} variant="danger" className="mr-auto">
                        Delete
                    </Button>
                    <Button disabled={isMutating} variant="light" onClick={() => onHide(false)}>
                        Cancel
                    </Button>
                    <Button disabled={isMutating} variant="primary" type="submit">Edit</Button>
                </Modal.Footer>
            </form>
        </Modal>
    );

}
