import React, {useEffect, useState} from "react";
import {Modal, Button} from "react-bootstrap";
import {CircularProgress, FormControl, InputLabel, MenuItem, Select, TextField} from "@material-ui/core";
import {
    useGetContentByIdQuery,
    useUpdateContentMutation
} from "../../../../../../redux/api/TestBundles/testBundlesApi"
import {useFormik} from "formik";
import * as Yup from "yup";

export default function Edit({show, onHide, contentId, totalCount}) {
    const editData = useGetContentByIdQuery(contentId)
    const [updateContent, {isLoading, isSuccess}] = useUpdateContentMutation();

    const [orderList, setOrderList] = useState([])

    function handleSubmit(values) {
        const {title} = values
        const body = {id: contentId, title, order: values.order}
        updateContent(body)
    };

    const initialValues = {
        title: "",
        order: "",
    };

    const validationSchema = Yup.object({
        title: Yup.string().required("Please enter a title.")
    })

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            handleSubmit(values)
        }
    });

    const getInputClasses = (fieldName) => {
        if (formik.touched[fieldName] && formik.errors[fieldName]) {
            return true;
        }
        if (formik.touched[fieldName] && !formik.errors[fieldName]) {
            return false;
        }

        return false;
    };

    useEffect(() => {
        if (totalCount) {
            let arr = [];
            let count = totalCount + 1
            for (let i = 1; i < count; i++) {
                arr.push(i)
            }
            setOrderList(arr)
        }
    }, [totalCount])

    useEffect(() => {
        if (isSuccess) {
            onHide(false)
        }
    });

    useEffect(() => {
        if (editData.isSuccess) {
            const {title, order} = editData.data
            formik.setFieldValue("title", title);
            formik.setFieldValue("order", order)
        }
    }, [editData.isLoading])


    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Create a content</Modal.Title>
            </Modal.Header>
            <form onSubmit={formik.handleSubmit}>
                <Modal.Body>
                    {editData.isLoading
                        ? <div className="d-flex justify-content-center"><CircularProgress style={{color: "#34A9FC"}}/>
                        </div>
                        : <div className="col-12 pl-4 pr-4">
                            <div className="row">
                                <div className="col-12 col-md-12 col-lg-12">
                                    <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                        <TextField
                                            name="title"
                                            id="title"
                                            label="Title"
                                            placeholder="Title"
                                            className="w-100"
                                            error={getInputClasses("title")}
                                            {...formik.getFieldProps("title")}
                                        />
                                        {formik.touched.title && formik.errors.title ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">
                                                    {formik.errors.title}
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-12 col-md-12 col-lg-12">
                                    <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                        <FormControl
                                            error={getInputClasses("order")}
                                            className="w-100"
                                            placeholder="order"
                                        >
                                            <InputLabel id="order">Order</InputLabel>
                                            <Select
                                                {...formik.getFieldProps("order")}
                                                labelId="order"
                                                id="order"
                                            >
                                                <MenuItem value="">
                                                    <em>Not selected</em>
                                                </MenuItem>
                                                {orderList.map((order, index) => <MenuItem key={index}
                                                                                           value={order}>{order}</MenuItem>)}
                                            </Select>
                                            {formik.touched.order && formik.errors.order ? (
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block">
                                                        {formik.errors.order}
                                                    </div>
                                                </div>
                                            ) : null}
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                        </div>

                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={() => onHide(false)}>Close</Button>
                    <Button type="submit" variant="info">Update</Button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}