import { configureStore } from '@reduxjs/toolkit';
import {  useDispatch, useSelector } from 'react-redux';
import { persistStore ,FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER} from 'redux-persist';
import {reducers,middleWares} from "./reducersAndMiddlewares";

export const store = configureStore({
    reducer: reducers,
    devTools: process.env.NODE_ENV === 'development',
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
            }}).concat(middleWares),
});

export const persistor = persistStore(store);
export const useAppDispatch = () => useDispatch();
export const useAppSelector = useSelector;