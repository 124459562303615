import React, {useState, useCallback} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {CircularProgress, Divider} from "@material-ui/core";
import { FaRegCheckCircle } from "react-icons/fa";
import {
    useGetSpecificationsQuery,
    useGetSpecificationByIdQuery
} from "../../../redux/api/Specifications/specificationsApi";
import EditIcon from "../../components/icons/EditIcon";
import DeleteIcon from "../../components/icons/DeleteIcon";
import RenderIf from "../../components/RenderIf";
import Edit from "./modals/Edit";
import Remove from "./modals/Remove";

const useStyles = makeStyles({
    wrapper: {
        height: " calc(100% - 65px)",
        paddingTop: "12px",
        display: "flex",
        flexDirection: "column",
        gap: "8px"
    },
    divider: {
        height: "1px",
        width: "100%"
    },
    card: {
        padding: "24px",
        background: "#FFFFFF",
        boxShadow: "0px 2px 18px rgba(62, 74, 97, 0.12)",
        borderRadius: "12px",
        display: "flex",
        justifyContent: "space-between"
    },
    cardTextContainer: {
        display: "flex",
        gap: "16px"
    },
    cardText: {
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "21px",
        color: "#424460",
    },
    cardButtonsContainer: {
        display: "flex",
        gap: "32px"
    },
    cardButton: {
        background: "unset",
        border: "unset",
        fontWeight: "500",
        fontSize: "16px",
        lineHeight: "24px",
        color: "#424460",
        "& svg": {
            marginRight: "8px"
        }
    },
    progress: {
        color: "#34a9fc"
    }
});

export default function Specifications() {
    const [showEdit, setShowEdit] = useState(false);
    const [deleteState, setDeleteState] = useState({
        showDelete: false,
        id: undefined
    })
    const [specificationId, setSpecificationId] = useState(undefined);
    const {data, isLoading} = useGetSpecificationsQuery();
    const edit = useGetSpecificationByIdQuery(specificationId, {skip: !Boolean(specificationId)})
    const classes = useStyles();
    const handleEdit = useCallback((id) => {
        setSpecificationId(id);
        setShowEdit(prevState => !prevState);
    }, [])

    const onHide = useCallback(() => {
        setShowEdit(false);
        setSpecificationId(undefined);
    }, []);

    const onDelete = useCallback((id) => {
        setDeleteState(prevState => ({
            showDelete: !prevState.showDelete,
            id: id
        }))
    }, []);

    const onCloseDelete = useCallback(() => {
        setDeleteState(prevState => ({
            ...prevState, showDelete: false
        }))
    }, [])
    return (
        <div className="row bg-white rounded h-100">
            <div className="col-12 d-flex align-items-center py-4 height-65">
                <h1 className="display-4 mb-0">Specifications</h1>
                <div className="ml-auto">
                    <button onClick={() => setShowEdit(true)} className="button-primary">Add a new specification
                    </button>
                </div>
            </div>
            <Divider className={classes.divider}/>
            <div className={`col-12 ${classes.wrapper}`}>
                {
                    isLoading ?
                        <div className="d-flex justify-content-center"><CircularProgress className={classes.progress}/>
                        </div>
                        : data?.data?.map(specification => (
                            <div key={specification.id} className={classes.card}>
                                <div className={classes.cardTextContainer}>
                                    <span className={classes.cardText}>{specification.name}</span>
                                    <RenderIf condition={specification.isFree}>
                                        <span className={classes.cardText}>Free access <FaRegCheckCircle/></span>
                                    </RenderIf>
                                </div>
                                <div className={classes.cardButtonsContainer}>
                                    <button onClick={() => handleEdit(specification.id)} className={classes.cardButton}>
                                        <EditIcon/>
                                    </button>
                                    <button onClick={() => onDelete(specification.id)} className={classes.cardButton}>
                                        <DeleteIcon/>
                                    </button>
                                </div>
                            </div>
                        ))
                }
            </div>
            {
                specificationId && showEdit && !edit.isFetching ? (
                    <Edit show={showEdit} onHide={onHide} isLoading={edit.isFetching} editData={edit.data} setSpecificationId={setSpecificationId}/>
                ) : showEdit && !specificationId && !edit.isFetching ? (
                    <Edit show={showEdit} onHide={onHide} editData={{id: undefined, title: ""}}/>
                ) : null
            }
            <RenderIf condition={deleteState.showDelete}>
                <Remove show={deleteState.showDelete} onHide={onCloseDelete} id={deleteState.id}/>
            </RenderIf>
        </div>
    )
}