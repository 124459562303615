import React, {useEffect} from "react";
import {Modal, Button} from "react-bootstrap";
import {useDeleteSpecificationMutation} from "../../../../redux/api/Specifications/specificationsApi";

export default function Remove({show, onHide, id}) {
    const [deleteSpecification, {isLoading, isSuccess}] = useDeleteSpecificationMutation();

    const onDeleteSpecification = (e) => {
        e.preventDefault();
        deleteSpecification(id)
    };

    useEffect(() => {
        if (isSuccess) {
            onHide();
        }

    }, [isSuccess]);

    return (
        <Modal show={show} onHide={onHide} aria-labelledby="delete" centered>
            <form onSubmit={onDeleteSpecification}>
                <Modal.Header closeButton>
                    <Modal.Title id="delete">Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-12 pl-4 pr-4">
                        <div className="classes.title">Are you sure to delete?
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={() => onHide(false)} disabled={isLoading}>
                        Cancel
                    </Button>
                    <Button variant="danger" type="submit" disabled={isLoading}>Delete</Button>
                </Modal.Footer>
            </form>
        </Modal>
    );

}
