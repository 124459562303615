import React, {useState} from "react";
import {Modal, Button} from "react-bootstrap";
import TextField from "@material-ui/core/TextField/index";

export default function Filter({onHide, show, state, setState}) {

    const onResetFilter = () => {
        setState(prevState => ({
            ...prevState,
            firstName: "",
            lastName: "",
            email: ""
        }))
    };

    const filterInputsUpdate = (e) => {
        e.persist()
        setState(prevState => ({
            ...prevState, [e.target.name]: e.target.value
        }))
    }

    return (
        <Modal
            size="sm"
            show={show}
            onHide={() => onHide(false)}
            aria-labelledby="create"
            centered
            className="modal-right pr-0"
        >
            <Modal.Header closeButton>
                <Modal.Title id="filter">Users filter</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="col-12 pl-4 pr-4">
                    <div className="row">
                        <div className="col-12 col-md-12 col-lg-12">
                            <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                <TextField
                                    id="firstName"
                                    name="firstName"
                                    label="First name"
                                    placeholder="First name"
                                    className="w-100"
                                    value={state.firstName}
                                    onChange={(e) => filterInputsUpdate(e)}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-12">
                            <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                <TextField
                                    id="lastName"
                                    name="lastName"
                                    label="Last name"
                                    placeholder="Last name"
                                    className="w-100"
                                    value={state.lastName}
                                    onChange={(e) => filterInputsUpdate(e)}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-12">
                            <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                <TextField
                                    id="email"
                                    name="email"
                                    label="Email"
                                    placeholder="Email"
                                    className="w-100"
                                    value={state.email}
                                    onChange={(e) => filterInputsUpdate(e)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="danger"
                    onClick={() => {
                        onResetFilter();
                    }}
                >
                    Reset
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

