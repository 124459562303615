import {useEffect} from "react";
import {Button, Modal} from "react-bootstrap";
import {TextField} from "@material-ui/core";
import {useFormik} from "formik";
import * as yup from "yup";
import {useUpdateCategoryMutation, useGetCategoryByIdQuery} from "redux/api/Categories/categoriesApi";

const validationSchema = yup.object({
    name: yup.string().required("Please enter a name."),
})

export default function Edit({show, onHide, id}) {
    const {data} = useGetCategoryByIdQuery(id);
    const [updateCategory, {isSuccess, isLoading}] = useUpdateCategoryMutation();

    const formik = useFormik({
        initialValues: {
            name: "",
        },
        validationSchema,
        onSubmit: (values) => {
            const body = {
                id, ...values
            }
            updateCategory(body);
        }
    })

    const getInputClasses = (fieldName) => {
        if (formik.touched[fieldName] && formik.errors[fieldName]) {
            return true;
        }
        if (formik.touched[fieldName] && !formik.errors[fieldName]) {
            return false;
        }

        return false;
    };

    useEffect(() => {
        if(data) {
            const {name} = data;
            formik.setFieldValue("name", name);
        }
    }, [data])

    useEffect(() => {
        if(isSuccess)
            onHide(false)
    }, [isSuccess])

    return(
        <Modal centered size="md" show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Edit the category
                </Modal.Title>
            </Modal.Header>
            <form onSubmit={formik.handleSubmit}>
                <Modal.Body>
                    <div className="col-12">
                        <div className="row">
                            <div className="col-12">
                                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                    <TextField
                                        id="name"
                                        name="name"
                                        label="Name"
                                        className="w-100"
                                        value={formik.values.name}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={getInputClasses("name")}
                                    />
                                    {formik.touched.name && formik.errors.name ? (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                {formik.errors.name}
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="light"
                        onClick={() => {
                            onHide(false);
                        }}
                    >
                        Close
                    </Button>
                    <Button disabled={isLoading} variant="info" type="submit" className={`px-9`}>
                        Update
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}