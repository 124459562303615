import React, {useState} from "react";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ViewIcon from "../../../../components/icons/ViewIcon";
import EditIcon from "../../../../components/icons/EditIcon";
import DeleteIcon from "../../../../components/icons/DeleteIcon";
import Create from "./modals/Create";
import Edit from "./modals/Edit";
import Remove from "./modals/Remove";
import View from "./modals/View";
import CircularProgress from "@material-ui/core/CircularProgress/index";
import AlertComponent from "../../../../../_metronic/_helpers/AlertComponent";
import Tooltip from "@material-ui/core/Tooltip";
import {Divider} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import {useGetExercisesQuery} from "../../../../../redux/api/Books/booksApi";
import Status from "../Status";
import {useDispatch} from "react-redux";
import {setStep} from "../../../../../redux/features/Books/booksSlice"

export default function Exercises({
                                      renderMethods,
                                      setRender,
                                      bookName,
                                      contentId,
                                      contentName
                                  }) {

    const [showCreate, setShowCreate] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [showRemove, setShowRemove] = useState(false);
    const [showView, setShowView] = useState(false);
    const [viewContent, setViewContent] = useState({});
    const [exerciseId, setExerciseId] = useState("");
    const [contentPage, setContentPage] = useState(1)
    const [skip, setSkip] = useState(0);
    const [take, setTake] = useState(10);

    const dispatch = useDispatch()

    const {data, isLoading} = useGetExercisesQuery({
        skip, take, contentId
    });


    const useStyles = makeStyles({
        wrapper: {
            height: " calc(100% - 65px)",
            paddingTop: "12px"
        },
        divider: {
            height: "1px",
            width: "100%"
        },
        label: {
            width: "100px"
        }
    });

    const handleChangePage = (e, page) => {
        setContentPage(page)
        const tempSkip = (page - 1) * take
        setSkip(tempSkip);
        window.scrollTo({top: 0, behavior: "smooth"})
    };

    const classes = useStyles();

    return (
        <>
            <div className="col-12 d-flex align-items-center py-4 height-65">
                <button onClick={() => {
                    dispatch(setStep(renderMethods.contents))
                }} className="button-primary mr-2">
                    <ArrowBackIcon/>
                </button>
                <h1 className="display-6 mb-0">Exercises of "{contentName}"</h1>
                <div className="ml-auto">
                    <button onClick={() => setShowCreate(true)} className="button-primary">Add an exercise
                    </button>
                </div>
            </div>
            <Divider className={classes.divider}/>
            <div className={`col-12 ${classes.wrapper}`}>
                {isLoading
                    ? <div className="d-flex justify-content-center"><CircularProgress style={{color: "#34A9FC"}}/>
                    </div>
                    : !isLoading && !data?.data?.length ?
                        <AlertComponent message="There is no content." variant="info"/>
                        : data?.data.map((item, index) => (
                            <div key={index} className="content-card">
                                <h1 className="content-card__title">{item.order}.
                                    <span className="ml-2">Exercise: {item.exercise}</span>
                                </h1>
                                <div className="content-card__operations">
                                    <Status status={item.status} id={item.id} url={"book-exercises"}/>
                                    <Tooltip title="Show exercise">
                                        <button onClick={() => {
                                            setViewContent({
                                                exercise: item.exercise,
                                                page: item.page,
                                                url: item.url,
                                            });
                                            setShowView(true)
                                        }}
                                                className="content-card__button">
                                            <ViewIcon/> Show exercise
                                        </button>
                                    </Tooltip>
                                    <Tooltip title="Edit exercise">
                                        <button onClick={() => {
                                            setExerciseId(item.id);
                                            setShowEdit(true)
                                        }}
                                                className="content-card__button">
                                            <EditIcon/> Edit
                                        </button>
                                    </Tooltip>
                                    <Tooltip title="Delete exercise">
                                        <button onClick={() => {
                                            setExerciseId(item.id);
                                            setShowRemove(true)
                                        }}
                                                className="content-card__button">
                                            <DeleteIcon/> Delete
                                        </button>
                                    </Tooltip>
                                </div>
                            </div>
                        ))
                }
                {data?.count > take && (
                    <div className="py-2">
                        <Pagination onChange={(e, page) => handleChangePage(e, page)}
                                    count={Math.ceil(data?.count / take)} page={contentPage}/>
                    </div>
                )}
                {showCreate && (
                    <Create show={showCreate} onHide={setShowCreate} contentId={contentId}
                            order={data?.data?.length + 1}/>
                )}
                {showEdit && (
                    <Edit totalCount={data?.count} show={showEdit} onHide={setShowEdit}
                          exerciseId={exerciseId}/>
                )}
                {showRemove && (
                    <Remove show={showRemove} onHide={setShowRemove} exerciseId={exerciseId}/>
                )}
                {showView && (
                    <View show={showView} onHide={setShowView} viewContent={viewContent}/>
                )}
            </div>
        </>
    )
}