import React, {useEffect} from "react";
import {Modal, Button} from "react-bootstrap";
import {useUpdateBookOrderMutation} from "../../../../../../../redux/api/Books/booksApi";


export default function Delivery({show, onHide, deliveryStatus}) {

    const [updateBookOrder, {isLoading, isSuccess}] = useUpdateBookOrderMutation()

    useEffect(() => {
        if (isSuccess) {
            onHide();
        }

    }, [isSuccess]);

    const onUpdateOrder = (e) => {
        e.preventDefault();
        updateBookOrder(deliveryStatus)
    };


    return (
        <Modal show={show} onHide={onHide} aria-labelledby="delivery" centered>
            <form onSubmit={onUpdateOrder}>
                <Modal.Header closeButton>
                    <Modal.Title id="delete">Delivery</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-12 pl-4 pr-4">
                        <div className="row">Are you sure to set the order as {deliveryStatus.delivered ? "delivered" : "not delivered"}?</div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={() => onHide(false)}>
                        No
                    </Button>
                    <Button disabled={isLoading} variant="primary" type="submit">Yes</Button>
                </Modal.Footer>
            </form>
        </Modal>
    );

}
