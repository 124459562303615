import React, { useState, useCallback } from "react";
import PlusIcon from "@material-ui/icons/Add";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import { format } from "date-fns";
import RenderIf from "app/components/RenderIf";
import ExamTableCell from "./components/ExamTableCell";
import ExamTableInput from "./components/ExamTableInput";
import CreateExam from "./modals/CreateExam";
import EditExam from "./modals/EditExam";
import DeleteExam from "./modals/DeleteExam";
import {
  useGetLessonGroupQuizzesQuery,
  useSendEmailQuizzesMutation,
} from "redux/api/Groups/groupsApi";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FilterExam from "./modals/FilterExam";
import { filterParams, dateConvertGeneral } from "../../../../../Helpers";
import { useFormik } from "formik";

const useStyles = makeStyles({
  tableBodyRow: {
    "&:nth-child(even)": {
      background: "#F6F6F6",
    },
  },
  tableHeadCell: {
    background: "#34A9FC",
    padding: "16px 12px",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.035em",
    color: "#FFFFFF",
    whiteSpace: "nowrap",

    "&:first-child": {
      borderTopLeftRadius: "12px",
    },

    "&:last-child": {
      borderTopRightRadius: "12px",
    },
  },
  tableHeadCellButton: {
    cursor: "pointer",
  },
  text: {
    margin: "unset",
    display: "flex",
    gap: "4px",
    fontSize: "14px",
    textTransform: "capitalize",
  },
  textSecondary: {
    fontSize: "16px",
  },
  tableWrapper: {
    overflowX: "auto",
  },
});

const date = new Date();
date.setDate(1);
const fromDate = dateConvertGeneral(date);

const initialFilter = {
  fromDate,
  toDate: "",
};

export default function ExamList({ groupId, showSnackbar }) {
  const [showCreate, setShowCreate] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [quizId, setQuizId] = useState();
  const [filter, setFilter] = useState(initialFilter);

  const params = filterParams({
    lessonGroupId: groupId,
    fromDate: filter.fromDate,
    toDate: filter.toDate,
  });

  const { data } = useGetLessonGroupQuizzesQuery(params);

  const sortedStudents = data?.students?.slice().sort((a, b) => {
    return `${a.firstName} ${a.lastName}`.localeCompare(
      `${b.firstName} ${b.lastName}`
    );
  });

  const toggleCreate = useCallback(() => setShowCreate((val) => !val), []);
  const toggleEdit = useCallback(() => setShowEdit((val) => !val), []);
  const toggleDelete = useCallback(() => setShowDelete((val) => !val), []);
  const toggleFilter = useCallback(() => setShowFilter((val) => !val), []);

  const onResetFilter = useCallback(() => setFilter(initialFilter), []);

  const handleShowEditQuiz = useCallback(
    (id) => () => {
      setQuizId(id);
      toggleEdit();
    },
    [toggleEdit]
  );

  const classes = useStyles();

  const [
    sendEmailQuizzes,
    { isSuccess, isLoading: isMutating, error },
  ] = useSendEmailQuizzesMutation();

  const formik = useFormik({
    initialValues: {
      studentId: 0,
      lessonGroupId: 0,
    },
    onSubmit: (values) => {
      sendEmailQuizzes(values);
    },
  });

  React.useEffect(() => {
    if (isSuccess) {
      showSnackbar("Email sent.");
    }
  }, [isSuccess]);

  return (
    <>
      <div>
        <IconButton color="primary" onClick={toggleCreate}>
          <PlusIcon />
        </IconButton>
        <FormControlLabel
          control={
            <Switch
              checked={showFilter}
              onChange={toggleFilter}
              color="secondary"
            />
          }
          label={showFilter ? "Hide filter" : "Show filter"}
          className="ml-auto mb-0"
        />
        <form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
          }}
          style={{ display: "inline-block" }}
        >
          <button
            className="sendBtn large"
            type="submit"
            onClick={() => {
              formik.setValues({
                studentId: null,
                lessonGroupId: groupId,
              });
            }}
          >
            Send Email
          </button>
        </form>
      </div>

      <div className={classes.tableWrapper}>
        <table>
          <thead>
            <tr>
              <td className={classes.tableHeadCell}>Students</td>
              {data?.lessonGroupQuizzes?.map((quiz) => (
                <Tooltip key={quiz.id} title="Edit the quiz" placement="top">
                  <td
                    className={`${classes.tableHeadCell} ${classes.tableHeadCellButton}`}
                    onClick={handleShowEditQuiz(quiz.id)}
                  >
                    <p className={classes.text}>
                      <span className={classes.textSecondary}>{quiz.name}</span>
                    </p>
                    <p className={classes.text}>
                      out of:{" "}
                      <span className={classes.textSecondary}>
                        {quiz.questionCount}
                      </span>
                    </p>
                    <p className={classes.text}>
                      <span className={classes.textSecondary}>
                        {quiz.date && format(new Date(quiz.date), "dd.MM.yyyy")}
                      </span>
                    </p>
                  </td>
                </Tooltip>
              ))}
            </tr>
          </thead>
          <tbody>
            {/* {data?.students?.map(student => (
                       <tr key={student.id} className={classes.tableBodyRow}>
                           <ExamTableCell>
                               {`${student.firstName} ${student.lastName}`}
                           </ExamTableCell>
                           {student.studentQuizResults?.map(results => (
                               <ExamTableInput key={results.id} value={results.answeredQuestionCount} id={results.id} showSnackbar={showSnackbar}>
                                   {results.answeredQuestionCount}
                               </ExamTableInput>
                           ))}
                       </tr>
                   ))} */}
            {sortedStudents?.map((student) => (
              <tr key={student.id} className={classes.tableBodyRow}>
                <ExamTableCell style={{ width: "auto" }}>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      formik.handleSubmit();
                    }}
                    className="d-flex align-items-center"
                    style={{ gap: "14px" }}
                  >
                    <button
                      className="sendBtn"
                      type="submit"
                      onClick={() => {
                        formik.setValues({
                          studentId: student.id,
                          lessonGroupId: null,
                        });
                      }}
                    >
                      Send Email
                    </button>
                    <span>{`${student.firstName} ${student.lastName}`}</span>
                  </form>
                </ExamTableCell>
                {student.studentQuizResults?.map((results) => (
                  <ExamTableInput
                    key={results.id}
                    value={results.answeredQuestionCount}
                    id={results.id}
                    showSnackbar={showSnackbar}
                  >
                    {results.answeredQuestionCount}
                  </ExamTableInput>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <RenderIf condition={showCreate}>
        <CreateExam show={showCreate} onHide={toggleCreate} groupId={groupId} />
      </RenderIf>
      <RenderIf condition={showEdit && quizId}>
        <EditExam
          show={showEdit}
          onHide={toggleEdit}
          quizId={quizId}
          toggleDelete={toggleDelete}
        />
      </RenderIf>
      <RenderIf condition={showDelete && quizId}>
        <DeleteExam
          show={showDelete}
          onHide={toggleDelete}
          quizId={quizId}
          toggleEdit={toggleEdit}
        />
      </RenderIf>
      <RenderIf condition={showFilter}>
        <FilterExam
          show={showFilter}
          onHide={toggleFilter}
          filter={filter}
          setFilter={setFilter}
          onResetFilter={onResetFilter}
        />
      </RenderIf>

    </>
  );
}
