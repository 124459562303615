export default function Preview({imageSrc}){
    return(
        <>

            {imageSrc && (
                <div className="image-preview">
                    <img src={imageSrc} alt="image"/>
                </div>
            )}
        </>
    )
}