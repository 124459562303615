import React from "react";
import {Divider} from "@material-ui/core";
import {useGetProductsQuery} from "redux/api/Dashboard/dashboardApi";
import RenderIf from "app/components/RenderIf";
import {items} from "app/constants";
import {Skeleton} from "./components";
import useStyles from "./styles";

export default function Dashboard() {
    const classes = useStyles();

    const {data, isLoading} = useGetProductsQuery();

    if(isLoading) return <Skeleton/>

    return (
        <div className="row bg-white rounded h-100">
            <div className="col-12 d-flex align-items-center py-4 height-65">
                <h1 className="display-4 mb-0">Dashboard</h1>
            </div>
            <Divider className={classes.divider}/>
            <div className={classes.items}>
                <div className={classes.itemsWrapper}>
                    <RenderIf condition={data && !isLoading}>
                        {data?.items?.map((product, index) => (
                            <div key={index} className={classes.item}>
                                <p className={classes.itemCount}>{product?.count}</p>
                                <p className={classes.itemName}>{items[product?.itemType]}</p>
                                <div className={classes.itemLine}></div>
                            </div>
                        ))}
                        <div className={classes.item}>
                            <p className={classes.itemCount}>{data?.studentCount}</p>
                            <p className={classes.itemName}>Students</p>
                            <div className={classes.itemLine}></div>
                        </div>
                        <div className={classes.item}>
                            <p className={classes.itemCount}>{data?.userCount}</p>
                            <p className={classes.itemName}>Users</p>
                            <div className={classes.itemLine}></div>
                        </div>
                    </RenderIf>
                </div>

            </div>
        </div>
    )
}