import {createApi} from '@reduxjs/toolkit/query/react';
import FetchBase from '../FetchBase';
import {userApi} from './userApi';
import {setToken} from "../../features/User/userSlice";


export const authApi = createApi({
    reducerPath: 'authApi',
    baseQuery: FetchBase,
    endpoints: (builder) => ({
        loginUser: builder.mutation({
            query(data) {
                return {
                    url: 'users/authenticate',
                    method: 'POST',
                    body: data,
                };
            },
            async onQueryStarted(args, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                    await dispatch(setToken(data));
                    await dispatch(userApi.endpoints.getMe.initiate(data.token));
                } catch (error) {}
            },
        }),
    }),
});

export const {
    useLoginUserMutation,
} = authApi;

