import {createApi} from "@reduxjs/toolkit/query/react";
import {APIBaseQuery} from "../AxiosBase";
import {v4 as uuid} from "uuid";

export const testBundlesApi = createApi({
    reducerPath: "testBundlesApi",
    baseQuery: APIBaseQuery,
    keepUnusedDataFor: 1,
    tagTypes: ["TestBundles", "Contents", "Questions"],
    endpoints: (build) => ({
        getCategories: build.query({
            query() {
                return {
                    url: `categories`
                }
            }
        }),
        getTestBundles: build.query({
            query(filter) {
                const {skip, take,} = filter
                return {
                    url: `items?itemTypes=3&skip=${skip}&take=${take}`
                }
            },
            providesTags: ["TestBundles"]
        }),
        getTestBundleById: build.query({
            query(id) {
                return {
                    url: `items/${id}`
                }
            }
        }),
        createTestBundle: build.mutation({
            query(body) {
                return {
                    url: `items`,
                    method: "POST",
                    headers: {
                        "x-requestid": uuid()
                    },
                    data: body
                }
            },
            invalidatesTags: ["TestBundles"]
        }),
        updateTestBundle: build.mutation({
            query(body) {
                return {
                    url: `items`,
                    method: "PUT",
                    headers: {
                        "x-requestid": uuid()
                    },
                    data: body
                }
            },
            invalidatesTags: ["TestBundles"]
        }),
        updateTestBundleStatus: build.mutation({
            query(body) {
                const {status, id, url} = body
                return {
                    url: `${url}/${id}/status`,
                    method: "PUT",
                    headers: {
                        "x-requestid": uuid()
                    },
                    data: {status}
                }
            },
        }),
        removeTestBundle: build.mutation({
            query(id) {
                return {
                    url: `items/${id}`,
                    method: "DELETE",
                    headers: {
                        "x-requestid": uuid()
                    },
                }
            },
            invalidatesTags: ["TestBundles"]
        }),
        getContents: build.query({
            query(filter = {}) {
                const {testBundleId, skip, take} = filter
                return {
                    url: `item-contents?itemId=${testBundleId}&skip=${skip}&take=${take}&sortField=order&orderBy=true`
                }
            },
            providesTags: ["Contents"]
        }),
        getContentById: build.query({
            query(id) {
                return {
                    url: `item-contents/${id}`
                }
            }
        }),
        createContent: build.mutation({
            query(body) {
                return {
                    url: `item-contents`,
                    method: "POST",
                    headers: {
                        "x-requestid": uuid()
                    },
                    data: body
                }
            },
            invalidatesTags: ["Contents"]
        }),
        updateContent: build.mutation({
            query(body) {
                return {
                    url: `item-contents`,
                    method: "PUT",
                    headers: {
                        "x-requestid": uuid()
                    },
                    data: body
                }
            },
            invalidatesTags: ["Contents"]
        }),
        updateContentStatus: build.mutation({
            query(body) {
                const {id, status} = body;
                return {
                    url: `item-contents/${id}/status`,
                    method: "PUT",
                    headers: {
                        "x-requestid": uuid()
                    },
                    data: {status}
                }
            },
            invalidatesTags: ["Contents"]
        }),
        removeContent: build.mutation({
            query(id) {
                return {
                    url: `item-contents/${id}`,
                    method: "DELETE",
                    headers: {
                        "x-requestid": uuid()
                    }
                }
            },
            invalidatesTags: ["Contents"]
        }),
        getQuestions: build.query({
            query(filter) {
                const {skip, take, contentId} = filter
                return {
                    url: `test-bundle-questions?contentId=${contentId}&skip=${skip}&take=${take}&sortField=order&orderBy=true`
                }
            },
            providesTags: ["Questions"]
        }),
        getQuestionById: build.query({
            query(id) {
                return {
                    url: `test-bundle-questions/${id}`
                }
            }
        }),
        createQuestion: build.mutation({
            query(body) {
                return {
                    url: `test-bundle-questions`,
                    method: "POST",
                    headers: {
                        "x-requestid": uuid()
                    },
                    data: body
                }
            },
            invalidatesTags: ["Questions"]
        }),
        updateQuestion: build.mutation({
            query(body) {
                return {
                    url: `test-bundle-questions`,
                    method: "PUT",
                    headers: {
                        "x-requestid": uuid()
                    },
                    data: body
                }
            },
            invalidatesTags: ["Questions"]
        }),
        updateQuestionStatus: build.mutation({
            query(body) {
                const {id, status} = body
                return {
                    url: `test-bundle-questions/${id}/status`,
                    method: "PUT",
                    headers: {
                        "x-requestid": uuid()
                    },
                    data: {status}
                }
            },
            invalidatesTags: ["Questions"]
        }),
        removeQuestion: build.mutation({
            query(id) {
                return {
                    url: `test-bundle-questions/${id}`,
                    method: "DELETE",
                    headers: {
                        "x-requestid": uuid()
                    }
                }
            },
            invalidatesTags: ["Questions"]
        })
    })
});

export const {
    useGetCategoriesQuery,
    useGetTestBundlesQuery,
    useGetTestBundleByIdQuery,
    useCreateTestBundleMutation,
    useUpdateTestBundleMutation,
    useUpdateTestBundleStatusMutation,
    useRemoveTestBundleMutation,
    useGetContentsQuery,
    useGetContentByIdQuery,
    useCreateContentMutation,
    useUpdateContentMutation,
    useUpdateContentStatusMutation,
    useRemoveContentMutation,
    useGetQuestionsQuery,
    useGetQuestionByIdQuery,
    useCreateQuestionMutation,
    useUpdateQuestionMutation,
    useUpdateQuestionStatusMutation,
    useRemoveQuestionMutation
} = testBundlesApi