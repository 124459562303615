import React, {useState, useEffect, useRef} from "react";
import {Modal, Button} from "react-bootstrap";
import {makeStyles} from "@material-ui/core/styles";
import ReactPlayer from "react-player";
import {useGetExplanationVideoQuery} from "../../../../redux/api/Courses/coursesApi";
import Input from "@material-ui/core/Input";
import Button2 from "@material-ui/core/Button";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../../_metronic/_helpers";
import LinearProgress from "../components/LinearProgress";
import axios from "axios";
import {v4 as uuid} from "uuid";
import {useSelector} from "react-redux";

const useStyles = makeStyles((theme) => ({
    textarea: {
        width: "100% !important",
        height: "100px !important",
        border: "1px solid rgba(0, 0, 0, 0.12)",
    },
    info: {
        color: "#F7A76C"
    }
}));

export default function View(props) {
    const classes = useStyles();
    const {onHide, show, questionId} = props;
    const {token} = useSelector(({user}) => ({token: user.token}));
    const formRef = useRef(null);

    const [url, setUrl] = useState("");
    const [message, setMessage] = useState("")
    const [file, setFile] = useState("");
    const [fileName, setFileName] = useState("");
    const [videoSrc, setVideoSrc] = useState("");
    const [percent, setPercent] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const data = useGetExplanationVideoQuery(questionId);


    const handleVideo = (e) => {
        let reader = new FileReader();
        let file = e.target.files[0];
        setFile(e.target.value);
        setFileName(file.name);
        reader.onloadend = () => {
            setVideoSrc(reader.result)
        };

        reader.readAsDataURL(file);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true)
        const asset = new FormData(formRef.current);
        axios.put(`${process.env.REACT_APP_API_URL}questions/${questionId}/question-video`, asset, {
            headers: {
                "x-requestid": uuid(),
                "Authorization": `Bearer ${token}`
            },
            onUploadProgress: ({loaded, total}) => {
                const tempPercent = Math.round((loaded / total) * 100)
                if (percent < tempPercent) {
                    setPercent(tempPercent)
                }
            }
        })
            .then(() => {
                setIsLoading(false);
                setPercent(0);
                onHide(false);
            })
            .catch(() => {
                setIsLoading(false);
                setPercent(0);
            })
    }

    useEffect(() => {
        if(data.status === "fulfilled") {
            const {data: {url}} = data;
            setUrl(url)
        } else {
            setMessage("There is no explanation video. You can upload with the button below")
        }


    }, [data?.isSuccess])


    return (
        <Modal show={show} onHide={onHide} aria-labelledby="view" centered size="lg">

            <Modal.Header closeButton>
                <Modal.Title id="view"></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {url && (
                    <ReactPlayer controls={true} width="100%" url={url}/>
                )}
                {!data.isSuccess &&  message && (
                    <div className="d-flex justify-content-center">
                        <span className={classes.info}>{message}</span>
                    </div>
                )}
                <form ref={formRef}>
                    <div className="mt-2 d-flex flex-column align-items-center">
                        <label htmlFor="contained-button-file">
                            <Input
                                accept="video/*"
                                id="contained-button-file"
                                type="file"
                                name="UrlFile"
                                label="File {Path}"
                                placeholder="File Path"
                                className="w-100"
                                onChange={handleVideo}
                                value={file}
                                hidden
                            />
                            <Button2 disabled={isLoading} variant="contained" component="span">
                                <SVG
                                    src={toAbsoluteUrl(
                                        "/media/svg/icons/Devices/Video-camera.svg"
                                    )}
                                    className="mr-2"
                                />
                                {file ? "File has been chosen" : "Change the explanation video"}
                            </Button2>
                        </label>
                        {fileName}
                    </div>

                    {isLoading && <LinearProgress progress={percent}/>}
                    {isLoading && file && <span>Please wait until the video loads. This may take a while</span>}
                    {file && (
                        <div className="d-flex justify-content-center">
                            <Button type="submit" onClick={handleSubmit} variant="info">Upload the video</Button>
                        </div>
                    )}
                </form>
            </Modal.Body>
        </Modal>
    );

}
