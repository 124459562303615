import React, {useState} from "react";
import {Divider, FormControlLabel, Switch} from "@material-ui/core";
import {useGetExamOrdersQuery} from "redux/api/Orders/ordersApi";
import Filter from "./modals/Filter";
import Table from "./components/Table";

export default function ExamOrders() {
    const [showFilter, setShowFilter] = useState(false);
    const [state, setState] = useState({
        skip: 0,
        take: 10,
        orderBy: false,
        sortField: 'created',
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        paymentProcess: "Approved",
        fromDate: "",
        toDate: "",
        name: "",
    });

    const {data, isLoading} = useGetExamOrdersQuery(state);

    return(
        <div className="row bg-white rounded h-100">
            <div className="col-12 d-flex align-items-center py-4 height-65">
                <h1 className="display-4 mb-0">Exam orders</h1>
                <FormControlLabel
                    control={
                        <Switch
                            color="secondary"
                            checked={showFilter}
                            onChange={() => setShowFilter(true)}
                        />
                    }
                    label="Show filter"
                    className="ml-auto mb-0"
                />
            </div>
            <Divider className="w-100"/>
            <Table data={data} isLoading={isLoading} state={state} setState={setState}/>
            {showFilter && (
                <Filter show={showFilter} onHide={setShowFilter} state={state} setState={setState} />
            )}
        </div>
    )
}