import {createApi} from '@reduxjs/toolkit/query/react';
import {APIBaseQuery} from "../AxiosBase";
import {v4 as uuid} from "uuid";


export const studentsApi = createApi({
    reducerPath: "studentsApi",
    baseQuery: APIBaseQuery,
    keepUnusedDataFor: 1,
    tagTypes: ["students"],
    endpoints: (builder) => ({
        getStudents: builder.query({
            query(filter) {
                const {skip, take, order, field, firstName, lastName, email} = filter
                return {
                    url: `students`,
                    params: {
                        skip, take, orderBy: order, sortField: field, deleted: false, firstName, lastName, email
                    }
                }
            },
            providesTags: ["students"]
        }),
        deleteStudent: builder.mutation({
            query(id) {
                return {
                    method: "DELETE",
                    url: `students/${id}`,
                    headers: {
                        "x-requestid": uuid()
                    }
                }
            },
            invalidatesTags: ["students"]
        }),
        getACTExam: builder.query({
            query(arg) {
                const {studentId} = arg;
                return {
                    url: `students/act-exam-results?studentId=${studentId}`
                }
            }, transformResponse: res => res.data
        }),
        getSATExam: builder.query({
            query(arg) {
                const {studentId} = arg;
                return {
                    url: `students/sat-exam-results?studentId=${studentId}`
                }
            }, transformResponse: res => res.data
        }),
        getPracticeTestResults: builder.query({
            query(id) {
                return {
                    url: `students/${id}/practice-test-progresses`
                }
            }
        }),
        getStudentById: builder.query({
            query(id) {
                return {
                    url: `students/${id}`
                }
            }
        }),
        editStudentCoin: builder.mutation({
            query(body) {
                return {
                    method: "PUT",
                    url: `students`,
                    data: body,
                    headers: {
                        "x-requestid": uuid()
                    }
                }
            }
        })
    })
})

export const {useGetStudentsQuery, useDeleteStudentMutation, useGetACTExamQuery, useGetSATExamQuery, useGetPracticeTestResultsQuery, useEditStudentCoinMutation, useGetStudentByIdQuery} = studentsApi;