import React, {useState, useEffect} from "react";
import {Button} from "react-bootstrap";
import {makeStyles} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import ReactQuill from "app/components/ReactQuill";
import Loading from "app/components/Loading";
import {useSnackbar} from "app/components/Snackbar";
import {useCreateAboutMutation, useGetAboutQuery} from "redux/api/About/aboutApi";
import {Input, InputLabel} from "@material-ui/core";
import Button2 from "@material-ui/core/Button";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../_metronic/_helpers";
import Preview from "../Courses/components/Preview/Preview";

const useStyles = makeStyles({
    container: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        gap: "16px"
    },
    editor: {
        width: "100%",
        minHeight: "400px",
        "& .quill": {
            height: "100%"
        }
    },
    input: {
        alignSelf: "flex-start"
    },
    button: {
        backgroundColor: "#34a9fc",
        border: "unset",
        alignSelf: "flex-end"
    }
});

export default function About() {
    const {data, isLoading} = useGetAboutQuery();
    const [createAbout, {isLoading: isMutating, isSuccess}] = useCreateAboutMutation();
    const [state, setState] = useState(false);


    useEffect(() => {
        setState(val => !val)
    }, [])

    const {showSnackbar, SnackbarComponent} = useSnackbar();

    const [editorValue, setEditorValue] = useState("");
    const [studentCount, setStudentCount] = useState("");
    const [locationUrl, setLocationUrl] = useState("");
    const [imageSrc, setImageSrc] = useState("");
    const [file, setFile] = useState("");
    const [blob, setBlob] = useState();
    const classes = useStyles();

    function handleCoverImage(e) {
        let reader = new FileReader();
        let file = e.target.files[0];
        setBlob(file)
        setFile(e.target.value);

        reader.onloadend = () => {
            setImageSrc(reader.result);
        };

        reader.readAsDataURL(file);
    };

    function handleChangeEditor(e) {
        setEditorValue(e);
    }

    function handleChangeStudentCount(e) {
        const regex = new RegExp(/^[0-9]+$/);
        if (regex.test(e.target.value) || e.target.value === "") {
            setStudentCount(e.target.value);
        }
    }

    function handleChangeLocationUrl(e) {
        setLocationUrl(e.target.value);
    }

    function handleSubmit(e) {
        e.preventDefault();
        const formData = new FormData();
        formData.append("body", editorValue);
        formData.append("studentCount", studentCount);
        formData.append("locationUrl", locationUrl);
        formData.append("aboutFile", blob);
        createAbout(formData);
    }

    useEffect(() => {
        if (data) {
            setEditorValue(data?.body);
            setStudentCount(data?.studentCount);
            setLocationUrl(data?.locationUrl);
            setImageSrc(data?.aboutImage);
        }
    }, [data])

    useEffect(() => {
        if (isSuccess) {
            showSnackbar("Your changes have been updated successfully!")
        }
    }, [isSuccess])

    return (
        <div className="bg-white rounded h-100 p-4">
            {
                isLoading
                    ? <Loading/>
                    : <form className={classes.container} onSubmit={handleSubmit}>
                        <div className={classes.editor}>
                            <ReactQuill
                                value={editorValue}
                                onChange={handleChangeEditor}
                            />
                        </div>
                        <TextField
                            value={studentCount}
                            onChange={handleChangeStudentCount}
                            placeholder="Student count"
                            label="Student count"
                            className={classes.input}
                        />
                        <TextField
                            value={locationUrl}
                            onChange={handleChangeLocationUrl}
                            className={classes.input}
                            label="Location iframe url"
                        />
                        <div className="w-100 pt-2 pb-3">
                            <InputLabel id="profileFile">Cover
                                photo</InputLabel>
                            <label htmlFor="contained-button-file">
                                <Input
                                    accept="image/*"
                                    id="contained-button-file"
                                    type="file"
                                    name="profileFile"
                                    label="File {Path}"
                                    placeholder="File Path"
                                    className="w-100"
                                    onChange={handleCoverImage}
                                    value={file}
                                    hidden
                                />
                                <Button2 variant="contained" component="span">
                                    <SVG
                                        src={toAbsoluteUrl(
                                            "/media/svg/icons/Devices/Camera.svg"
                                        )}
                                        className="mr-2"
                                    />
                                    Upload image
                                </Button2>
                            </label>
                            <Preview imageSrc={imageSrc}/>
                        </div>
                        <Button type="submit" disabled={isMutating} className={classes.button}>Save</Button>
                    </form>
            }
            <SnackbarComponent/>
        </div>
    )
}