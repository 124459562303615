import React, {useState} from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export function useSnackbar(time = 6000) {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");

    const handleClick = (message = "") => {
        setMessage(message)
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const SnackbarComponent = () => (
        <Snackbar open={open} autoHideDuration={time} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success">
                {message}
            </Alert>
        </Snackbar>
    )


    return {
        SnackbarComponent,
        showSnackbar: handleClick
    }
}