import React, { useState, useCallback } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import { format } from "date-fns";
import RenderIf from "app/components/RenderIf";
import ExamTableCell from "./components/ExamTableCell";
import { useGetGroupNotesQuery } from "redux/api/Groups/groupsApi";
import { dateConvertGeneral } from "../../../../../Helpers";
import IconButton from "@material-ui/core/IconButton";
import { Add } from "@material-ui/icons";
import CreateNote from "./modals/CreateNote";
import EditNote from "./modals/EditNote";

const useStyles = makeStyles({
  tableBodyRow: {
    "&:nth-child(even)": {
      background: "#F6F6F6",
    },
  },
  tableHeadCell: {
    background: "#34A9FC",
    padding: "16px 12px",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.035em",
    color: "#FFFFFF",
    whiteSpace: "nowrap",

    "&:first-child": {
      borderTopLeftRadius: "12px",
    },

    "&:last-child": {
      borderTopRightRadius: "12px",
    },
  },
  tableHeadCellButton: {
    cursor: "pointer",
  },
  text: {
    margin: "unset",
    display: "flex",
    gap: "4px",
    fontSize: "14px",
    textTransform: "capitalize",
  },
  textSecondary: {
    fontSize: "16px",
  },
  tableWrapper: {
    overflowX: "auto",
  },
});

const date = new Date();
date.setDate(1);
const fromDate = dateConvertGeneral(date);

const initialFilter = {
  fromDate,
  toDate: "",
};

export default function NoteList({ groupId, showSnackbar }) {
  const [showCreate, setShowCreate] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [studentId, setStudentId] = useState();
  const [noteId, setNoteId] = useState();

  const { data: notes } = useGetGroupNotesQuery(groupId);

  const sortedStudents = notes?.data?.slice().sort((a, b) => {
    return a.studentUsername.localeCompare(b.studentUsername);
  });

  const toggleCreate = useCallback((studentId, noteId) => {
    setShowCreate((val) => !val);
    setStudentId(studentId);
    setNoteId(noteId);
  }, []);
  const toggleEdit = useCallback((studentId, noteId) => {
    setShowEdit((val) => !val);
    setStudentId(studentId);
    setNoteId(noteId);
  }, []);
  const toggleDelete = useCallback(() => setShowDelete((val) => !val), []);

  const classes = useStyles();

  return (
    <>
      <div className={classes.tableWrapper}>
        <table>
          <tbody>
            {/* {notes?.data?.map(student => (
                        <tr key={student.id} className={classes.tableBodyRow}>
                            <ExamTableCell>
                                {student.studentUsername}
                            </ExamTableCell>
                            {student.notes?.map(note => (
                                <ExamTableCell key={note.id}>
                                    <Tooltip title={note.description}>
                                    <button onClick={() => toggleEdit(student.studentId, note.id)} className="badge badge-primary hover">{format(new Date(note.created), 'MM.dd.yyyy')}</button>
                                    </Tooltip>
                                </ExamTableCell>
                            ))}
                            <ExamTableCell>
                                <Tooltip title="Add note">
                                    <IconButton onClick={() => toggleCreate(student.studentId, undefined)} className="bg-primary text-light">
                                        <Add/>
                                    </IconButton>
                                </Tooltip>
                            </ExamTableCell>
                        </tr>
                    ))} */}
            {sortedStudents?.map((student) => (
              <tr key={student.id} className={classes.tableBodyRow}>
                <ExamTableCell>{student.studentUsername}</ExamTableCell>
                {student.notes?.map((note) => (
                  <ExamTableCell key={note.id}>
                    <Tooltip title={note.description}>
                      <button
                        onClick={() => toggleEdit(student.studentId, note.id)}
                        className="badge badge-primary hover"
                      >
                        {format(new Date(note.created), "MM.dd.yyyy")}
                      </button>
                    </Tooltip>
                  </ExamTableCell>
                ))}
                <ExamTableCell>
                  <Tooltip title="Add note">
                    <IconButton
                      onClick={() => toggleCreate(student.studentId, undefined)}
                      className="bg-primary text-light"
                    >
                      <Add />
                    </IconButton>
                  </Tooltip>
                </ExamTableCell>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <RenderIf condition={showCreate}>
        <CreateNote
          show={showCreate}
          onHide={setShowCreate}
          lessonGroupId={groupId}
          studentId={studentId}
        />
      </RenderIf>
      <RenderIf condition={showEdit}>
        <EditNote
          show={showEdit}
          onHide={setShowEdit}
          studentId={studentId}
          lessonGroupId={groupId}
          noteId={noteId}
        />
      </RenderIf>
    </>
  );
}
