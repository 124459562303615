import React from "react";
import {Modal, Button} from "react-bootstrap";
import {makeStyles} from "@material-ui/core/styles"
import ReactPlayer from "react-player";

const useStyles = makeStyles({
    title: {
       display: "flex",
        gap: "12px"
    }
})

export default function View({show, onHide, viewContent}) {
    const classes = useStyles();

    return (
        <Modal size="lg" show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <div className={classes.title}>
                        <span>Exercise: {viewContent.exercise}</span>
                        <span>Page: {viewContent.page}</span>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <ReactPlayer width="100" url={viewContent.url}/>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => onHide(false)} variant="light">Close</Button>
            </Modal.Footer>
        </Modal>
    )
}