import {useEffect} from "react";
import {Button, Modal} from "react-bootstrap";
import {TextField} from "@material-ui/core";
import {useFormik} from "formik";
import * as yup from "yup";
import {useCreatePackageMutation, useGetItemsQuery} from "redux/api/Packages/packagesApi";
import Select from "../components/Select";

const validationSchema = yup.object({
    name: yup.string().required("Please fill this field."),
    price: yup.number().min(1).required("Please fill this field.").typeError("Please enter price."),
    items: yup.array().required("Please select item")
})

export default function Create({show, onHide}) {
    const {data} = useGetItemsQuery();
    const [createPackage, {isSuccess}] = useCreatePackageMutation();

    const formik = useFormik({
        initialValues: {
            name: "",
            price: "",
            items: []
        },
        validationSchema,
        onSubmit: (values) => {
            const body = {
                name: values.name,
                price: values.price,
                items: values.items.map(item => item.value)
            }
            createPackage(body);
        }
    })

    const getInputClasses = (fieldName) => {
        if (formik.touched[fieldName] && formik.errors[fieldName]) {
            return true;
        }
        if (formik.touched[fieldName] && !formik.errors[fieldName]) {
            return false;
        }

        return false;
    };

    useEffect(() => {
        if(isSuccess)
            onHide(false)
    }, [isSuccess])

    return(
        <Modal centered size="lg" show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Create a package
                </Modal.Title>
            </Modal.Header>
            <form onSubmit={formik.handleSubmit}>
                <Modal.Body>
                    <div className="col-12">
                        <div className="row">
                            <div className="col-12">
                                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                    <TextField
                                        id="name"
                                        name="name"
                                        label="Name"
                                        className="w-100"
                                        value={formik.values.name}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={getInputClasses("name")}
                                    />
                                    {formik.touched.name && formik.errors.name ? (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                {formik.errors.name}
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                    <TextField
                                        id="price"
                                        name="price"
                                        label="Price"
                                        className="w-100"
                                        value={formik.values.price}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={getInputClasses("price")}
                                    />
                                    {formik.touched.price && formik.errors.price ? (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                {formik.errors.price}
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                    <Select
                                        options={data}
                                        value={formik.values.items}
                                        onChange={(e) => formik.setFieldValue("items", e)}
                                    />
                                    {formik.touched.items && formik.errors.items ? (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                {formik.errors.items}
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="light"
                        onClick={() => {
                            onHide(false);
                        }}
                    >
                        Close
                    </Button>
                    <Button disabled={false} variant="info" type="submit" className={`px-9`}>
                        Create
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}