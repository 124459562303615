import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    practiceTestsState: [],
}

export const practiceTestsSlice = createSlice({
    initialState,
    name: "practiceTestsSlice",

    reducers: {
        setPracticeTests: (state, action) => {
            state.practiceTestsState = action.payload
        },
    }
});

export default practiceTestsSlice.reducer;
export const {setPracticeTests} = practiceTestsSlice.actions