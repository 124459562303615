import {createApi} from "@reduxjs/toolkit/query/react";
import {APIBaseQuery} from "../AxiosBase";
import {v4 as uuid} from "uuid";

export const dashboardApi = createApi({
    reducerPath: "dashboardApi",
    baseQuery: APIBaseQuery,
    keepUnusedDataFor: 1,
    endpoints: (build) => ({
        getProducts: build.query({
            query(arg) {
                return {
                    url: "dashboards"
                }
            }
        })
    })
})

export const {
    useGetProductsQuery
} = dashboardApi;