import React, {useState} from "react";
import TestBundlesComponent from "./components/TestBundles"
import Contents from "./components/Contents/Contents";
import Questions from "./components/Questions/Questions";

const renderMethods = {
    testBundles: "testBundles",
    contents: "contents",
    questions: "questions"
}

export default function TestBundles() {
    const [render, setRender] = useState(renderMethods.testBundles);

    const [testBundleId, setTestBundleId] = useState("");
    const [testBundleName, setTestBundleName] = useState("");
    const [contentName, setContentName] = useState("");
    const [contentId, setContentId] = useState("");
    const [subContentId, setSubContentId] = useState("");
    const [subContentName, setSubContentName] = useState("");
    const [questionId, setQuestionId] = useState("");
    const [questionName, setQuestionName] = useState("");

    const [contentPage, setContentPage] = useState(1)

    return (
        <div className="row bg-white rounded h-100">
            {render === renderMethods.testBundles
                ? <TestBundlesComponent
                    renderMethods={renderMethods}
                    setRender={setRender}
                    testBundleId={testBundleId}
                    setTestBundleId={setTestBundleId}
                    setTestBundleName={setTestBundleName}
                />
                :
                render === renderMethods.content ?
                    <Contents
                        testBundleId={testBundleId}
                        testBundleName={testBundleName}
                        renderMethods={renderMethods}
                        setRender={setRender}
                        contentId={contentId}
                        setContentId={setContentId}
                        contentName={contentName}
                        setContentName={setContentName}
                        contentPage={contentPage}
                        setContentPage={setContentPage}
                    />
                        : render === renderMethods.questions &&
                        <Questions
                            questionId={questionId}
                            setQuestionId={setQuestionId}
                            questionName={questionName}
                            setQuestionName={setQuestionName}
                            contentId={contentId}
                            contentName={contentName}
                            renderMethods={renderMethods}
                            setRender={setRender}
                        />
            }

        </div>
    )
}