import {CircularProgress, Divider} from "@material-ui/core";
import AlertComponent from "../../../../../_metronic/_helpers/AlertComponent";
import Card from "../Card/Card";
import Pagination from "@material-ui/lab/Pagination";
import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {useGetCoursesQuery, useGetSpecificationsQuery} from "../../../../../redux/api/Courses/coursesApi";
import Create from "../../modals/Create";
import Edit from "../../modals/Edit";
import Remove from "../../modals/Remove";

export default function Courses({
                                    setCourseId,
                                    setRender,
                                    renderMethods,
                                    setShowContents,
                                    setCourseName,
                                    courseName,
                                    courseId
                                }) {
    const [showCreate, setShowCreate] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [showRemove, setShowRemove] = useState(false);
    const [skip, setSkip] = useState(0);
    const [take, setTake] = useState(10);
    const [page, setPage] = useState(1);
    const {data, isLoading, refetch} = useGetCoursesQuery({
        skip: skip,
        take: take
    });
    const element = useGetSpecificationsQuery();

    const useStyles = makeStyles({
        wrapper: {
            height: " calc(100% - 65px)",
            paddingTop: "12px"
        },
        divider: {
            height: "1px",
            width: "100%"
        }
    });

    const classes = useStyles();

    const handleChangePage = (e, page) => {
        setPage(page)
        const tempSkip = (page - 1) * take
        setSkip(tempSkip);
        window.scrollTo({top: 0, behavior: "smooth"})
    }
    return (
        <>
            <div className="col-12 d-flex align-items-center py-4 height-65">
                <h1 className="display-4 mb-0">Courses</h1>
                <div className="ml-auto">
                    <button onClick={() => setShowCreate(true)} className="button-primary">Add a new course
                    </button>
                </div>
            </div>
            <Divider className={classes.divider}/>
            <div className={`col-12 ${classes.wrapper}`}>{isLoading ?
                <div className="d-flex justify-content-center"><CircularProgress style={{color: "#34A9FC"}}/>
                </div> : !isLoading && !data?.data?.length ? <AlertComponent variant="info"
                                                                             message="There is no content"/>
                    : data?.data?.map((course, index) => (
                        <div key={index} className="mb-3">
                            <Card course={course} setCourseId={setCourseId} setShowEdit={setShowEdit}
                                  setShowRemove={setShowRemove}
                                  setRender={setRender} renderMethods={renderMethods}
                                  setShowContents={setShowContents} setCourseName={setCourseName}
                            />
                        </div>
                    ))}
                {data?.count > take && (
                    <div className="py-2">
                        <Pagination onChange={(e, page) => handleChangePage(e, page)}
                                    count={Math.ceil(data?.count / take)} page={page}/>
                    </div>
                )}
            </div>
            {showCreate &&
                <Create refetch={refetch} show={showCreate} onHide={setShowCreate}
                        specifications={element.data ? element.data : []}/>}
            {showEdit && (
                <Edit refetch={refetch} show={showEdit} onHide={setShowEdit}
                      specifications={element.data ? element.data : []} courseId={courseId}/>
            )}
            {showRemove && (
                <Remove id={courseId} refetch={refetch} show={showRemove} onHide={setShowRemove}
                        courseName={courseName}/>
            )}
        </>
    )
}