import {createApi} from "@reduxjs/toolkit/query/react";
import {APIBaseQuery} from "../AxiosBase";
import {v4 as uuid} from "uuid";
import {setUndeliveredBook} from "../../features/Books/booksSlice";

export const booksApi = createApi({
    reducerPath: "booksApi",
    baseQuery: APIBaseQuery,
    keepUnusedDataFor: 1,
    tagTypes: ["Books", "Contents", "Sections", "Exercises", "BookOrders"],
    endpoints: (build) => ({
        getCategories: build.query({
            query() {
                return {
                    url: `categories`
                }
            }
        }),
        getBooks: build.query({
            query(filter = {}) {
                const {skip, take} = filter
                return {
                    url: `items?itemTypes=4&skip=${skip}&take=${take}`
                }
            },
            providesTags: ["Books"]
        }),
        getBookById: build.query({
            query(id) {
                return {
                    url: `items/${id}`
                }
            }
        }),
        createBook: build.mutation({
            query(body) {
                return {
                    url: `items`,
                    method: "POST",
                    headers: {
                        "x-requestid": uuid()
                    },
                    data: body
                }
            },
            invalidatesTags: ["Books"]
        }),
        updateBook: build.mutation({
            query(body) {
                return {
                    url: `items`,
                    method: "PUT",
                    headers: {
                        "x-requestid": uuid()
                    },
                    data: body
                }
            },
            invalidatesTags: ["Books"]
        }),
        updateBookStatus: build.mutation({
            query(data) {
                const {url, status, id} = data
                return {
                    url: `${url}/${id}/status`,
                    method: "PUT",
                    headers: {
                        "x-requestid": uuid()
                    },
                    data: {status}
                }
            }
        }),
        removeBook: build.mutation({
            query(id) {
                return {
                    url: `items/${id}`,
                    method: "DELETE",
                    headers: {
                        "x-requestid": uuid()
                    }
                }
            },
            invalidatesTags: ["Books"]
        }),
        getContents: build.query({
            query(filter = {}) {
                const {bookId, skip, take} = filter
                return {
                    url: `item-contents?itemId=${bookId}&skip=${skip}&take=${take}&sortField=order&orderBy=true`
                }
            },
            providesTags: ["Contents"]
        }),
        getContentById: build.query({
            query(id) {
                return {
                    url: `item-contents/${id}`
                }
            }
        }),
        createContent: build.mutation({
            query(body) {
                return {
                    url: `item-contents`,
                    method: "POST",
                    headers: {
                        "x-requestid": uuid()
                    },
                    data: body
                }
            },
            invalidatesTags: ["Contents"]
        }),
        updateContent: build.mutation({
            query(body) {
                return {
                    url: `item-contents`,
                    method: "PUT",
                    headers: {
                        "x-requestid": uuid()
                    },
                    data: body
                }
            },
            invalidatesTags: ["Contents"]
        }),
        removeContent: build.mutation({
            query(id) {
                return {
                    url: `item-contents/${id}`,
                    method: "DELETE",
                    headers: {
                        "x-requestid": uuid()
                    }
                }
            },
            invalidatesTags: ["Contents"]
        }),
        getSections: build.query({
            query(filter = {}) {
                const {skip, take, contentId} = filter
                return {
                    url: `book-sections?contentId=${contentId}&skip=${skip}&take=${take}&sortField=order&orderBy=true`
                }
            },
            providesTags: ["Sections"]
        }),
        getSectionById: build.query({
            query(id) {
                return {
                    url: `book-sections/${id}`
                }
            }
        }),
        createSection: build.mutation({
            query(body) {
                return {
                    url: `book-sections`,
                    method: "POST",
                    headers: {
                        "x-requestid": uuid()
                    },
                    data: body
                }
            },
            invalidatesTags: ["Sections"]
        }),
        updateSection: build.mutation({
            query(body) {
                return {
                    url: `book-sections`,
                    method: "PUT",
                    headers: {
                        "x-requestid": uuid()
                    },
                    data: body
                }
            },
            invalidatesTags: ["Sections"]
        }),
        removeSection: build.mutation({
            query(id) {
                return {
                    url: `book-sections/${id}`,
                    method: "DELETE",
                    headers: {
                        "x-requestid": uuid()
                    },
                }
            },
            invalidatesTags: ["Sections"]
        }),
        getExercises: build.query({
            query(filter) {
                const {skip, take, contentId} = filter
                return {
                    url: `book-exercises?contentId=${contentId}&skip=${skip}&take=${take}&sortField=order&orderBy=true`
                }
            },
            providesTags: ["Exercises"]
        }),
        getExerciseById: build.query({
            query(id) {
                return {
                    url: `book-exercises/${id}`
                }
            }
        }),
        createExercise: build.mutation({
            query(body) {
                return {
                    url: `book-exercises`,
                    method: "POST",
                    headers: {
                        "x-requestid": uuid()
                    },
                    data: body
                }
            },
            invalidatesTags: ["Exercises"]
        }),
        updateExercise: build.mutation({
            query(body) {
                return {
                    url: `book-exercises`,
                    method: "PUT",
                    headers: {
                        "x-requestid": uuid()
                    },
                    data: body
                }
            },
            invalidatesTags: ["Exercises"]
        }),
        removeExercise: build.mutation({
            query(id) {
                return {
                    url: `book-exercises/${id}`,
                    method: "DELETE",
                    headers: {
                        "x-requestid": uuid()
                    },
                }
            },
            invalidatesTags: ["Exercises"]
        }),
        getBookOrders: build.query({
            query(filter = {}) {
                const {bookId, skip, take, delivered, firstName, lastName, phone, email, address, orderBy} = filter;

                return {
                    url: `items/book-orders?skip=${skip}&take=${take}${bookId ? `&bookId=${bookId}` : ""}${delivered !== "" ? `&delivered=${delivered === "delivered"}`: ""}&orderBy=${orderBy}&sortField=created${firstName ? `&firstName=${firstName}` : ""}${lastName ? `&lastName=${lastName}` : ""}${email ? `&email=${email}` : ""}${address ? `&address=${address}` : ""}${phone ? `&phone=${phone}` : ""}`,
                }
            },
            providesTags: ["BookOrders"]
        }),
        getUndeliveredOrders: build.query({
            query() {
                return {
                    url: `items/book-orders?delivered=false`
                }
            },
            async onQueryStarted(arg, {queryFulfilled, dispatch}) {
                const {data} = await queryFulfilled;
                await dispatch(setUndeliveredBook(data.count > 0));
            }
        }),
        updateBookOrder: build.mutation({
            query(body) {
                return {
                    url: `items/book-orders`,
                    method: "PUT",
                    data: body
                }
            },
            invalidatesTags: ["BookOrders"]
        })
    })
})

export const {
    useGetCategoriesQuery,
    useGetBooksQuery,
    useGetBookByIdQuery,
    useCreateBookMutation,
    useUpdateBookMutation,
    useUpdateBookStatusMutation,
    useRemoveBookMutation,
    useGetContentsQuery,
    useGetContentByIdQuery,
    useCreateContentMutation,
    useUpdateContentMutation,
    useRemoveContentMutation,
    useGetSectionsQuery,
    useGetSectionByIdQuery,
    useCreateSectionMutation,
    useUpdateSectionMutation,
    useRemoveSectionMutation,
    useGetExercisesQuery,
    useGetExerciseByIdQuery,
    useCreateExerciseMutation,
    useUpdateExerciseMutation,
    useRemoveExerciseMutation,
    useGetBookOrdersQuery,
    useUpdateBookOrderMutation,
    useGetUndeliveredOrdersQuery
} = booksApi