import {useEffect, useState} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import {Button, Modal} from "react-bootstrap";
import {TextField, CircularProgress} from "@material-ui/core";
import Select from "react-select";
import {SketchPicker} from "react-color";
import {useGetStudentsQuery, useCreateGroupMutation} from "../../../../../../../redux/api/Groups/groupsApi";


export default function Create({show, onHide}) {
    const {data, isLoading} = useGetStudentsQuery();
    const [createGroup, {isLoading: isSending, isSuccess}] = useCreateGroupMutation();
    const [colorState, setColorState] = useState("#ffffff");

    const formik = useFormik({
        initialValues: {
            name: "",
            students: []
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Please enter a group name."),
        }),
        onSubmit: (values) => {
            createGroup({
                ...values, color: colorState
            })
        }
    })

    const handleChange = (e) => {
        const students = [];
        e.map(student => {
            students.push(student.value)
        });
        formik.setFieldValue("students", students)
    }

    const getInputClasses = (fieldName) => {
        if (formik.touched[fieldName] && formik.errors[fieldName]) {
            return true;
        }
        if (formik.touched[fieldName] && !formik.errors[fieldName]) {
            return false;
        }

        return false;
    };

    useEffect(() => {
        if(isSuccess) {
            onHide(false)
        }
    }, [isSuccess])


    return (
        <Modal centered size="lg" show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Create a group
                </Modal.Title>
            </Modal.Header>
            <form onSubmit={formik.handleSubmit}>
                <Modal.Body>
                    {isLoading ? <div className="d-flex justify-content-center"><CircularProgress style={{color: "#34a9fc"}}/></div>
                        : <div className="col-12">
                            <div className="row">
                                <div className="col-12">
                                    <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                        <TextField
                                            id="name"
                                            name="name"
                                            label="Group name"
                                            className="w-100"
                                            value={formik.values.name}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={getInputClasses("name")}
                                        />
                                        {formik.touched.name && formik.errors.name ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">
                                                    {formik.errors.name}
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                        <Select
                                            className="w-100"
                                            options={data}
                                            isMulti
                                            onChange={handleChange}
                                            multiValueContainer={false}
                                        />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                        <SketchPicker
                                            color={colorState}
                                            onChange={(e) => setColorState(e.hex)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="light"
                        onClick={() => {
                            onHide(false);
                        }}
                    >
                        Close
                    </Button>
                    <Button disabled={isSending} variant="info" type="submit" className={`px-9`}>
                        Create
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}