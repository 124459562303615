import React, {useState, useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Datepicker from "react-datepicker";
import EditTableIcon from "../../../../../../components/icons/EditTableIcon";
import DeleteTableIcon from "../../../../../../components/icons/DeleteTableIcon";
import TickIcon from '../../../../../../components/icons/TickIcon';
import {format} from 'date-fns';
import {useUpdateLessonMutation, useRemoveLessonMutation } from "../../../../../../../redux/api/Groups/groupsApi";


export default function TableCell({data, studentId, lessonLineId}) {
    const attendanceDate = data?.attendanceDate;
    const id = data?.id
    const formattedDate = attendanceDate && format(new Date(attendanceDate), "dd-MM-yyyy");

    const [showRemove, setShowRemove] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [date, setDate] = useState(new Date());

    const [updateLesson, {isLoading, isSuccess}] = useUpdateLessonMutation();
    const [removeLesson, {isSuccess: isRemoved}] = useRemoveLessonMutation();

    const useStyles = makeStyles({
        tableBodyCell: {
            width: "170px",
            padding: "20px 12px",
            borderRight: "2px solid #42446033",
            position: "relative",
            "&:last-child": {
                border: "unset"
            },
            "&:hover": {
                "& $tableBodyCellButtons": {
                    display: "flex"
                }
            }
        },
        tableBodyCellButtons: {
            height: "100%",
            display: showEdit ? "flex" : "none",
            flexDirection: "column",
            justifyContent: "space-between",
            position: "absolute",
            top: "0",
            right: "0"
        },
        tableBodyCellButton: {
            background: "unset",
            border: "unset",
        },
        tableBodyCellButtonContainer: {
            position: "relative"
        },
        tableBodyText: {
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "24px",
            color: "#424460"

        },
        tableBodyCellTickButton: {
            position: "absolute",
            zIndex: showRemove ? "1" : "-1",
            transform: showRemove ? "translateX(-22px)" : "translateX(0)",
            transition: "all ease 0.5s",
            background: "unset",
            "& svg": {
                color: "#32B432"
            }
        },
        datePicker: {
            maxWidth: "75px",
            width: "100%"
        }
    })

    const classes = useStyles();

    const handleChangeTime = (e) => {
        setDate(e);
        let formatted = format(new Date(e), "yyyy-MM-dd")
        updateLesson({
            id, attendanceDate: formatted, studentId, lessonLineId
        })

    }

    const onRemoveLesson = () => {
        removeLesson({id, studentId, lessonLineId})
    }

    useEffect(() => {
        if(attendanceDate) {
            setDate(new Date(attendanceDate))
        }
    }, [attendanceDate])

    useEffect(() => {
        if(isRemoved) {
            setShowRemove(false);
            setShowEdit(false);
        }
    }, [isRemoved])

    return (
        <td className={classes.tableBodyCell}>
            {data
                ? <span className={classes.tableBodyText}>
                <span>{formattedDate}</span>
                <div className={classes.tableBodyCellButtons}>
                    <Datepicker
                        onCalendarOpen={() => setShowEdit(true)}
                        onCalendarClose={() => setShowEdit(false)}
                        onChange={handleChangeTime}
                        selected={date}
                        customInput={<button className={classes.tableBodyCellButton}><EditTableIcon/></button>}
                    />

                    <div className={classes.tableBodyCellButtonContainer}>
                        <button onClick={onRemoveLesson} className={classes.tableBodyCellTickButton}><TickIcon/></button>
                        <button onClick={() => {
                            setShowRemove(prevState => !prevState);
                            setShowEdit(prevState => !prevState);
                        }}
                                className={classes.tableBodyCellButton}><DeleteTableIcon/></button>
                    </div>
                </div>
            </span>
                : null
            }
        </td>
    )
}