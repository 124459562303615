import React, {useState, useEffect} from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel/index";
import Card from "./components/Card/Card";
import {useGetCoursesQuery} from "../../../redux/api/Courses/coursesApi";
import Create from "./modals/Create";
import Edit from "./modals/Edit";
import Remove from "./modals/Remove";
import {useGetSpecificationsQuery} from "../../../redux/api/Courses/coursesApi";
import CoursesComponent from "./components/Courses"
import Contents from "./components/Contents/Contents";
import Videos from "./components/Videos/Videos";
import Questions from "./components/Questions/Questions";
import {CircularProgress} from "@material-ui/core";
import AlertComponent from "../../../_metronic/_helpers/AlertComponent";
import Pagination from '@material-ui/lab/Pagination';
import {Divider} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const renderMethods = {
    course: "course",
    content: "content",
    videos: "videos",
    questions: "questions"
}

export default function Courses() {
    const [courseName, setCourseName] = useState("");
    const [contentName, setContentName] = useState("");
    const [contentId, setContentId] = useState("");
    const [videoTitle, setVideoTitle] = useState("");
    const [videoId, setVideoId] = useState("");

    const [courseId, setCourseId] = useState("");
    const [render, setRender] = useState(renderMethods.course);
    const [showContents, setShowContents] = useState(false);

    const [contentPage, setContentPage] = useState(1);
    const [videoPage, setVideoPage] = useState(1);
    const [questionPage, setQuestionPage] = useState(1);


    return (
        <div className="row bg-white rounded h-100">
            {render === renderMethods.course
                ? <>
                    <CoursesComponent
                        setRender={setRender}
                        renderMethods={renderMethods}
                        setCourseId={setCourseId}
                        setCourseName={setCourseName}
                        setShowContents={setShowContents}
                        courseId={courseId}
                        courseName={courseName}
                    />
                </>
                : render === renderMethods.content ?
                    <Contents
                        id={courseId}
                        setRender={setRender}
                        renderMethods={renderMethods}
                        courseName={courseName}
                        contentName={contentName}
                        setContentName={setContentName}
                        contentId={contentId}
                        setContentId={setContentId}
                        contentPage={contentPage}
                        setContentPage={setContentPage}
                    />
                    : render === renderMethods.videos ?
                        <Videos
                            setRender={setRender}
                            renderMethods={renderMethods}
                            contentName={contentName}
                            contentId={contentId}
                            setVideoTitle={setVideoTitle}
                            videoTitle={videoTitle}
                            videoId={videoId}
                            setVideoId={setVideoId}
                            videoPage={videoPage}
                            setVideoPage={setVideoPage}
                        />
                        : render === renderMethods.questions &&
                        <Questions
                            setRender={setRender}
                            renderMethods={renderMethods}
                            videoTitle={videoTitle}
                            videoId={videoId}
                            questionPage={questionPage}
                            setQuestionPage={setQuestionPage}
                        />
            }
        </div>
    )
}