import {createSlice} from "@reduxjs/toolkit"


const initialState = {
    cities: [],
   
}

export const citiesSlice = createSlice({
    initialState,
    name: "citiesSlice",
   
    reducers: {
        setCities: (state,action) => {
            state.cities  = action.payload
        },
       
    }
})

export default citiesSlice.reducer;
export const {setCities} = citiesSlice.actions
