import React, {useState} from "react";
import {Divider} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles/index";
import {
    useGetPracticeTestsQuery,
    useUpdatePracticeTestStatusMutation
} from "../../../redux/api/PracticeTests/practiceTestsApi";
import {CircularProgress} from "@material-ui/core";
import AlertComponent from "../../../_metronic/_helpers/AlertComponent";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import ViewIcon from "../../components/icons/ViewIcon";
import EditIcon from "../../components/icons/EditIcon";
import DeleteIcon from "../../components/icons/DeleteIcon";
import Tooltip from "@material-ui/core/Tooltip";
import Create from "./modals/Create";
import Edit from "./modals/Edit";
import Remove from "./modals/Remove";
import Contents from "./components/Contents/Contents";
import SubContents from "./components/SubContents/SubContents";
import Questions from "./components/Questions/Questions";
import Pagination from "@material-ui/lab/Pagination";
import PracticeTestsComponent from "./components/PracticeTests"
const renderMethods = {
    practiceTest: "course",
    content: "content",
    subContent: "subContent",
    questions: "questions"
}

export default function PracticeTests() {
    const [render, setRender] = useState(renderMethods.practiceTest);
    const [practiceTestId, setPracticeTestId] = useState("");
    const [practiceTestName, setPracticeTestName] = useState("");
    const [contentName, setContentName] = useState("");
    const [contentId, setContentId] = useState("");
    const [subContentId, setSubContentId] = useState("");
    const [subContentName, setSubContentName] = useState("");
    const [questionId, setQuestionId] = useState("");
    const [questionName, setQuestionName] = useState("");

    const [contentPage, setContentPage] = useState(1)
    const [updatePracticeTestsStatus] = useUpdatePracticeTestStatusMutation()





    return (
        <div className="row bg-white rounded h-100">
            {render === renderMethods.practiceTest
                ? <PracticeTestsComponent
                    setRender={setRender}
                    renderMethods={renderMethods}
                    practiceTestId={practiceTestId}
                    setPracticeTestId={setPracticeTestId}
                    setPracticeTestName={setPracticeTestName}
                />
            : render === renderMethods.content ?
            <Contents
                practiceTestId={practiceTestId}
                practiceTestName={practiceTestName}
                renderMethods={renderMethods}
                setRender={setRender}
                itemId={practiceTestId}
                contentId={contentId}
                setContentId={setContentId}
                contentName={contentName}
                setContentName={setContentName}
                contentPage={contentPage}
                setContentPage={setContentPage}
            />
            : render === renderMethods.subContent ?
                    <SubContents
                        contentId={contentId}
                        contentName={contentName}
                        subContentId={subContentId}
                        setSubContentId={setSubContentId}
                        subContentName={subContentName}
                        setSubContentName={setSubContentName}
                        renderMethods={renderMethods}
                        setRender={setRender}
                    />
                        : render === renderMethods.questions &&
                        <Questions
                            questionId={questionId}
                            setQuestionId={setQuestionId}
                            questionName={questionName}
                            setQuestionName={setQuestionName}
                            subContentId={subContentId}
                            subContentName={subContentName}
                            renderMethods={renderMethods}
                            setRender={setRender}
                        />
            }

        </div>
    )
}