import { createApi } from "@reduxjs/toolkit/query/react";
import { APIBaseQuery } from "../AxiosBase";
import { v4 as uuid } from "uuid";
import {
  setGroup,
  addLessonLine,
  removeLessonLine,
  addLesson,
  updateLesson,
  removeLesson,
  updateRemark,
} from "../../features/Groups/groupsSlice";

export const groupsApi = createApi({
  reducerPath: "groupsApi",
  baseQuery: APIBaseQuery,
  keepUnusedDataFor: 1,
  tagTypes: [
    "groups",
    "groupStudents",
    "lessonLines",
    "lessonGroupQuizzes",
    "notes",
  ],
  endpoints: (build) => ({
    getStudents: build.query({
      query() {
        return {
          url: "students",
          params: {
            deleted: false,
          },
        };
      },
      transformResponse: (result) =>
        result.data.map((item) => ({
          label: `${item.firstName} ${item.lastName}`,
          value: item.id,
        })),
    }),
    getGroups: build.query({
      query(createdById) {
        return {
          url: `lesson-groups`,
          params: {
            // orderBy: false,
            // sortField: "created",
            ...(createdById && { createdById }),
          },
        };
      },
      providesTags: ["groups"],
    }),
    getGroupById: build.query({
      query(id) {
        return {
          url: `lesson-groups/${id}`,
        };
      },
      transformResponse: (result) => ({
        ...result,
        students: result.students.map((student) => ({
          label: `${student.firstName} ${student.lastName}`,
          value: student.id,
        })),
      }),
    }),
    getUsersList: build.query({
      query() {
        return {
          url: "users/list",
        };
      },
    }),
    createGroup: build.mutation({
      query(body) {
        return {
          url: `lesson-groups`,
          method: "POST",
          headers: {
            "x-requestid": uuid(),
          },
          data: body,
        };
      },
      invalidatesTags: ["groups"],
    }),
    updateGroup: build.mutation({
      query(body) {
        return {
          url: `lesson-groups`,
          method: "PUT",
          headers: {
            "x-requestid": uuid(),
          },
          data: body,
        };
      },
      invalidatesTags: ["groups"],
    }),
    removeGroup: build.mutation({
      query(id) {
        return {
          url: `lesson-groups/${id}`,
          method: "DELETE",
          headers: {
            "x-requestid": uuid(),
          },
        };
      },
      invalidatesTags: ["groups"],
    }),
    getGroupStudents: build.query({
      query(id) {
        return {
          url: `lesson-groups/students`,
          params: {
            lessonGroupId: id,
          },
        };
      },
      async onQueryStarted(args, { queryFulfilled, dispatch }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setGroup(data));
        } catch (err) {
          console.log(err);
        }
      },
      providesTags: ["groupStudents"],
    }),
    getLessonLines: build.query({
      query(id) {
        return {
          url: `lesson-lines`,
          params: {
            lessonGroupId: id,
          },
        };
      },
      providesTags: ["lessonLines"],
    }),
    createLessonLine: build.mutation({
      query(arg) {
        return {
          url: `lesson-lines`,
          method: "POST",
          headers: {
            "x-requestid": uuid(),
          },
          data: arg,
        };
      },
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(addLessonLine(data));
        } catch (err) {
          console.log(err);
        }
      },
    }),
    updateLessonLine: build.mutation({
      query(body) {
        return {
          url: `lesson-lines`,
          method: "PUT",
          headers: {
            "x-requestid": uuid(),
          },
          data: body,
        };
      },
    }),
    removeLessonLine: build.mutation({
      query(args) {
        const { lessonLineId } = args;
        return {
          url: `lesson-lines/${lessonLineId}`,
          method: "DELETE",
          headers: {
            "x-requestid": uuid(),
          },
        };
      },
      async onQueryStarted(args, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          dispatch(removeLessonLine(args));
        } catch (err) {
          console.log(err);
        }
      },
    }),
    createLesson: build.mutation({
      query(args) {
        const body = {
          lessonLineId: args.lessonLineId,
        };
        return {
          url: `lessons`,
          method: "POST",
          headers: {
            "x-requestid": uuid(),
          },
          data: body,
        };
      },
      async onQueryStarted(args, { queryFulfilled, dispatch }) {
        try {
          const { data } = await queryFulfilled;
          let payload = { ...data, ...args };
          dispatch(addLesson(payload));
        } catch (err) {
          console.log(err);
        }
      },
    }),
    createLessonsAll: build.mutation({
      query(lessonLineIds) {
        return {
          url: `lessons/all`,
          method: "POST",
          headers: {
            "x-requestid": uuid(),
          },
          data: { lessonLineIds },
        };
      },
      invalidatesTags: ["groupStudents"],
    }),
    updateLesson: build.mutation({
      query(args) {
        const body = {
          id: args.id,
          attendanceDate: args.attendanceDate,
        };
        return {
          url: `lessons`,
          method: "PUT",
          headers: {
            "x-requestid": uuid(),
          },
          data: body,
        };
      },
      async onQueryStarted(args, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          dispatch(updateLesson(args));
        } catch (err) {
          console.log(err);
        }
      },
    }),
    removeLesson: build.mutation({
      query(args) {
        const { id } = args;
        return {
          url: `lessons/${id}`,
          method: "DELETE",
          headers: {
            "x-requestid": uuid(),
          },
        };
      },
      async onQueryStarted(args, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          dispatch(removeLesson(args));
        } catch (err) {}
      },
    }),
    updateRemark: build.mutation({
      query(body) {
        return {
          url: `lesson-groups/students`,
          method: "PUT",
          headers: {
            "x-requestid": uuid(),
          },
          data: body,
        };
      },
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          dispatch(updateRemark(arg));
        } catch (err) {}
      },
    }),
    removeStudent: build.mutation({
      query({ groupId, studentId }) {
        return {
          url: `lesson-groups/${groupId}/students/${studentId}`,
          method: "DELETE",
          headers: {
            "x-requestid": uuid(),
          },
        };
      },
      invalidatesTags: ["groupStudents"],
    }),
    moveStudent: build.mutation({
      query(body) {
        return {
          url: `lesson-groups/students/move`,
          method: "PUT",
          data: body,
          headers: {
            "x-requestid": uuid(),
          },
        };
      },
      invalidatesTags: ["groupStudents"],
    }),
    getLessonGroupQuizzes: build.query({
      query(params) {
        return {
          url: "lesson-group-quizzes",
          params,
        };
      },
      providesTags: ["lessonGroupQuizzes"],
    }),
    getLessonGroupQuizById: build.query({
      query(id) {
        return {
          url: `lesson-group-quizzes/${id}`,
        };
      },
    }),
    createLessonGroupQuiz: build.mutation({
      query(body) {
        return {
          url: "lesson-group-quizzes",
          method: "POST",
          headers: {
            "x-requestid": uuid(),
          },
          data: body,
        };
      },
      invalidatesTags: ["lessonGroupQuizzes"],
    }),
    updateLessonGroupQuiz: build.mutation({
      query(body) {
        return {
          url: "lesson-group-quizzes",
          method: "PUT",
          headers: {
            "x-requestid": uuid(),
          },
          data: body,
        };
      },
      invalidatesTags: ["lessonGroupQuizzes"],
    }),
    updateLessonGroupQuizResult: build.mutation({
      query(body) {
        return {
          url: "lesson-group-quizzes/result",
          method: "PUT",
          headers: {
            "x-requestid": uuid(),
          },
          data: body,
        };
      },
    }),
    deleteLessonGroupQuiz: build.mutation({
      query(id) {
        return {
          url: `lesson-group-quizzes/${id}`,
          method: "DELETE",
          headers: {
            "x-requestid": uuid(),
          },
        };
      },
      invalidatesTags: ["lessonGroupQuizzes"],
    }),
    getGroupNotes: build.query({
      query(lessonGroupId) {
        return {
          url: "notes",
          params: {
            lessonGroupId,
          },
        };
      },
      transformResponse: (res) => {
        return {
          ...res,
          data: res.data.map((student) => ({
            ...student,
            notes: student.notes.reverse(),
          })),
        };
      },
      providesTags: ["notes"],
    }),
    getStudentNote: build.query({
      query(id) {
        return {
          url: `notes/${id}`,
        };
      },
    }),
    createNote: build.mutation({
      query(data) {
        return {
          url: `notes`,
          method: "POST",
          headers: {
            "x-requestid": uuid(),
          },
          data,
        };
      },
      invalidatesTags: ["notes"],
    }),
    updateNote: build.mutation({
      query(data) {
        return {
          url: `notes`,
          method: "PUT",
          headers: {
            "x-requestid": uuid(),
          },
          data,
        };
      },
      invalidatesTags: ["notes"],
    }),
    deleteNote: build.mutation({
      query(id) {
        return {
          url: `notes/${id}`,
          method: "DELETE",
          headers: {
            "x-requestid": uuid(),
          },
        };
      },
      invalidatesTags: ["notes"],
    }),
    sendEmailQuizzes: build.mutation({
      query(data) {
        return {
          url: `lesson-group-quizzes/email`,
          method: "POST",
          headers: {
            "x-requestid": uuid(),
          },
          data,
        };
      },
      invalidatesTags: ["groups"],
    }),
  }),
});

export const {
  useGetStudentsQuery,
  useGetGroupsQuery,
  useGetGroupByIdQuery,
  useGetUsersListQuery,
  useCreateGroupMutation,
  useUpdateGroupMutation,
  useRemoveGroupMutation,
  useGetGroupStudentsQuery,
  useGetLessonLinesQuery,
  useCreateLessonLineMutation,
  useUpdateLessonLineMutation,
  useRemoveLessonLineMutation,
  useCreateLessonMutation,
  useUpdateLessonMutation,
  useRemoveLessonMutation,
  useUpdateRemarkMutation,
  useRemoveStudentMutation,
  useMoveStudentMutation,
  useGetLessonGroupQuizzesQuery,
  useGetLessonGroupQuizByIdQuery,
  useCreateLessonGroupQuizMutation,
  useUpdateLessonGroupQuizMutation,
  useUpdateLessonGroupQuizResultMutation,
  useDeleteLessonGroupQuizMutation,
  useGetGroupNotesQuery,
  useGetStudentNoteQuery,
  useCreateLessonsAllMutation,
  useCreateNoteMutation,
  useUpdateNoteMutation,
  useDeleteNoteMutation,
  useSendEmailQuizzesMutation,
} = groupsApi;
