import React, {useCallback} from "react";
import {Button, Modal} from "react-bootstrap";
import TextField from "@material-ui/core/TextField";

export default function FilterExam({show, onHide, filter, setFilter, onResetFilter}) {
    const filterInputsUpdate = useCallback((e) => {
        setFilter(prevState => ({
            ...prevState, [e.target.name]: e.target.value
        }))
    }, []);

    return (
        <Modal
            size="sm"
            show={show}
            onHide={() => onHide(false)}
            aria-labelledby="create"
            centered
            className="modal-right pr-0"
        >
            <Modal.Header closeButton>
                <Modal.Title id="filter">Exam filter</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="col-12 pl-4 pr-4">
                    <div className="row">
                        <div className="col-12 col-md-12 col-lg-12">
                            <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                <TextField
                                    type="date"
                                    id="fromDate"
                                    name="fromDate"
                                    label="From date"
                                    placeholder="From date"
                                    className="w-100"
                                    value={filter.fromDate}
                                    onChange={(e) => filterInputsUpdate(e)}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-12">
                            <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                <TextField
                                    type="date"
                                    id="toDate"
                                    name="toDate"
                                    label="To date"
                                    placeholder="To date"
                                    className="w-100"
                                    value={filter.toDate}
                                    onChange={(e) => filterInputsUpdate(e)}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            </div>
                        </div>

                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="danger"
                    onClick={() => {
                        onResetFilter();
                    }}
                >
                    Reset
                </Button>
            </Modal.Footer>
        </Modal>
    );
}