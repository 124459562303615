import React from "react";
import {Modal} from "react-bootstrap";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    container: {
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, minmax(260px, 1fr))",
        gap: "8px"
    },
    tableContainer: {},
    title: {
        fontWeight: "500",
        fontSize: "20px",
        lineHeight: "30px",
        textAlign: "center",
        color: "#050a46",
        padding: "24px 0",
    },
    table: {
        width: "100%",
        background: "#fff",
        boxShadow: "0 2px 18px rgba(62,74,97,.12)",
        borderRadius: "12px",
    },
    tableHeading: {
        padding: "16px",
        fontWeight: "500",
        fontSize: "16px",
        lineHeight: "24px",
        color: "#050a46",
        borderRight: "1px solid rgba(66,68,96,.251)",
        borderBottom: "1px solid rgba(66,68,96,.251)",

        "&:last-child": {
            borderRight: "none"
        }
    },
    tableData: {
        padding: "16px",
        fontWeight: "500",
        fontSize: "16px",
        lineHeight: "24px",
        color: "#050a46",
        borderRight: "1px solid rgba(66,68,96,.251)",

        "&:last-child": {
            borderRight: "none"
        }
    },
    tableDataRow: {
        borderBottom: "1px solid rgba(66,68,96,.251)",

        "&:last-child": {
            borderBottom: "none"
        }
    }
});

export default function Mistakes({onHide, show, mistakes}) {
    const classes = useStyles();

    return (
        <Modal size='lg' show={show} onHide={onHide} aria-labelledby="examMistakes" centered>
            <Modal.Header closeButton>
                <Modal.Title id="delete">Wrong answers</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className={classes.container}>
                    {mistakes?.map((mistake, index) =>(
                        <div key={index} className={classes.tableContainer}>
                            <h1 className={classes.title}>
                                {mistake.sectionName}
                            </h1>
                            <table className={classes.table}>
                                <thead>
                                <tr>
                                    <th className={classes.tableHeading}>
                                        Question number
                                    </th>
                                    <th className={classes.tableHeading}>
                                        Chosen answer
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {mistake?.questions?.map((question, index) => (
                                    <tr key={index} className={classes.tableDataRow}>
                                        <td className={classes.tableData}>
                                            {question.questionNumber}
                                        </td>
                                        <td className={classes.tableData}>{question.chosenAnswer}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    ))}
                </div>
            </Modal.Body>
        </Modal>
    );

}
