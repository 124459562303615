import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    groups: [],
    group: {
        count: "",
        data: []
    }
}

export const groupsSlice = createSlice({
    initialState,
    name: "groupSlice",
    reducers: {
        setGroups: (state, action) => {
            state.groups = action.payload
        },
        setGroup: (state, action) => {
            state.group = action.payload
        },
        addLessonLine: (state, action) => {
            const {studentId, id, lessonGroupId, paid} = action.payload;

            const newLine = {
                id, lessonGroupId, studentId, paid, lessons: []
            }

            state.group = {
                ...state.group, data: state.group.data.map(student => {
                    if (student.studentId === studentId) {
                        return {
                            ...student, student: {
                                ...student.student, lessonLines: [...student.student.lessonLines, newLine]
                            }
                        }
                    } else {
                        return student
                    }
                })
            }
        },
        removeLessonLine: (state, action) => {
            const {studentId, lessonLineId} = action.payload;
            state.group = {
                ...state.group, data: state.group.data.map(student => {
                    if (student.studentId === studentId) {
                        return {
                            ...student, student: {
                                ...student.student,
                                lessonLines: student.student.lessonLines.filter(line => line.id !== lessonLineId)
                            }
                        }
                    } else {
                        return student
                    }
                })
            }
        },
        addLesson: (state, action) => {
            const {studentId, lessonLineId, id, attendanceDate} = action.payload;
            const newLesson = {id, attendanceDate, lessonLineId};


            state.group = {
                ...state.group, data: state.group.data.map(student => {
                    if (student.studentId === studentId) {
                        return {
                            ...student, student: {
                                ...student.student, lessonLines: student.student.lessonLines.map(line => {
                                    if (line.id === lessonLineId) {
                                        return {
                                            ...line, lessons: [...line.lessons, newLesson]
                                        }
                                    } else {
                                        return line
                                    }
                                })
                            }
                        }
                    } else {
                        return student
                    }
                })
            }
        },
        updateLesson: (state, action) => {
            const {id, studentId, lessonLineId, attendanceDate} = action.payload;

            state.group = {
                ...state.group, data: state.group.data.map((student) => {
                    if (studentId === student.studentId) {
                        return {
                            ...student, student: {
                                ...student.student, lessonLines: student.student.lessonLines.map(line => {
                                    if (line.id === lessonLineId) {
                                        return {
                                            ...line, lessons: line.lessons.map(lesson => {
                                                if (lesson.id === id) {
                                                    return {
                                                        ...lesson, attendanceDate
                                                    }
                                                } else {
                                                    return lesson
                                                }
                                            })
                                        }
                                    } else {
                                        return line
                                    }
                                })
                            }
                        }
                    } else {
                        return student
                    }
                })
            }
        },
        removeLesson: (state, action) => {
            const {id, studentId, lessonLineId} = action.payload;

            state.group = {
                ...state.group, data: state.group.data.map(student => {
                    if (student.studentId === studentId) {
                        return {
                            ...student, student: {
                                ...student.student, lessonLines: student.student.lessonLines.map(line => {
                                    if (line.id === lessonLineId) {
                                        return {
                                            ...line, lessons: line.lessons.filter(lesson => lesson.id !== id)
                                        }
                                    } else {
                                        return line
                                    }
                                })
                            }
                        }
                    } else {
                        return student
                    }
                })
            }
        },
        updateRemark: (state, action) => {
            const {id, remark} = action.payload;

            state.group = {
                ...state.group, data: state.group.data.map(student => {
                    if(student.id === id) {
                        return {
                            ...student, remark
                        }
                    } else {
                        return student
                    }
                })
            }
        }
    }

})

export default groupsSlice.reducer;
export const {setGroups, setGroup, addLessonLine, removeLessonLine, addLesson, updateLesson, removeLesson, updateRemark} = groupsSlice.actions;