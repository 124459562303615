import React, {useEffect} from "react";
import {Modal, Button} from "react-bootstrap";
import {makeStyles} from "@material-ui/core/styles";
import {useRemovePromoCodeMutation} from "redux/api/PromoCode/promoCodeApi";

const useStyles = makeStyles((theme) => ({
    textarea: {
        width: "100% !important",
        height: "100px !important",
        border: "1px solid rgba(0, 0, 0, 0.12)",
    },
    warning: {
        color: "red",
        fontSize: "16px",
        marginTop: "10px"
    },
    title: {
        fontSize: "17px",
    }
}));

export default function Remove({show, onHide, id, groupName}) {
    const classes = useStyles();

    const [removePromoCode, {isSuccess, isLoading}] = useRemovePromoCodeMutation()



    useEffect(() => {
        if (isSuccess) {
            onHide();
        }

    }, [isLoading]);

    const onDeleteUser = (e) => {
        e.preventDefault();
        removePromoCode(id)
    };


    return (
        <Modal show={show} onHide={onHide} aria-labelledby="delete" centered>
            <form onSubmit={onDeleteUser}>
                <Modal.Header closeButton>
                    <Modal.Title id="delete">Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-12 pl-4 pr-4">
                        <div className={`row ${classes.title}`}>Are you sure to delete ?
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={() => onHide(false)}>
                        Cancel
                    </Button>
                    <Button disabled={isLoading} variant="danger" type="submit">Delete</Button>
                </Modal.Footer>
            </form>
        </Modal>
    );

}
