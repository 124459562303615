import React from "react";

function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="none"
            viewBox="0 0 16 16"
        >
            <path
                stroke="#5BC35B"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.2"
                d="M7.336 1.334H6.003c-3.334 0-4.667 1.333-4.667 4.667v4c0 3.333 1.333 4.666 4.667 4.666h4c3.333 0 4.666-1.333 4.666-4.666V8.667"
            ></path>
            <path
                stroke="#5BC35B"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="1.2"
                d="M10.697 2.014L5.444 7.267c-.2.2-.4.593-.44.88l-.287 2.006c-.107.727.407 1.234 1.133 1.134L7.857 11c.28-.04.673-.24.88-.44l5.254-5.253c.906-.907 1.333-1.96 0-3.294-1.334-1.333-2.387-.906-3.294 0zM9.938 2.768A4.763 4.763 0 0013.23 6.06"
            ></path>
        </svg>
    );
}

export default Icon;
